<template lang="pug">
  .AppImageUnderBox(
    :style="style")
</template>

<script>

export default {
  name: 'AppImage',
  props: {
    backgroundColor: {
      type: String,
      default: '#E7EBF0',
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {

    aspectRatio() {
      if (!this.width || !this.height) return null;
      return (this.height / this.width) * 100;
    },

    style() {

      const style = { backgroundColor: this.backgroundColor };
      style.width = `${this.width}px`;
      style.height = 0;
      let widthPercentage = this.width / 1320
      if(widthPercentage > 1) widthPercentage = 1;
      style.paddingTop = `${this.aspectRatio*widthPercentage}%`;
      style.marginTop = `${-1*this.aspectRatio*widthPercentage}%`;

      return style;

    },
  },

  mounted() {
  },
};
</script>

<style scoped lang="scss">
// Responsive image styles.
.AppImageUnderBox {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
}
</style>
