import Vue from 'vue'
import App from './App.vue'
import router from './router'
import OutsideClick from '@/directives/OutsideClick.js'

//setup the data for photography
//Vue.prototype.$photography = [];

Vue.directive('outside-click', OutsideClick)

//AOS Init
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
  duration: 1000,
  once: true,
  offset: 100
});

import checkView from 'vue-check-view'
Vue.use(checkView)

Vue.config.productionTip = false

// Plugin
//import CursorFx from '@luxdamore/vue-cursor-fx';
//import '@luxdamore/vue-cursor-fx/dist/CursorFx.css';

// Install
//Vue.use(
//    CursorFx
//);

Vue.mixin({
  methods: {

    urlMaker(name, url, extension) {
      //switch with salleedesign for non local loading tests
      const publicPath = process.env.BASE_URL
      //const publicPath = 'http://salleedesign.com/'
      var ext = ''
      if( extension == undefined ) ext = '.jpg'
      else ext = `.${extension}`

      return `${publicPath}${url}/${name}${ext}`
    },

    fullImagePage(){
      return this.$route.name == 'PhotographyDetailPage' || this.$route.name == 'Stealth'
    },

    //ANIMATION HELPERS
    trackTime( id ){
      const [entry] = performance.getEntriesByName(id);
      if (!entry) {
        performance.mark(id);
        return 0;
      }
      return performance.now() - entry.startTime;
    },

    delay(callback, duration){
      const tick = () =>
        this.getProgress(time) < 1 ? requestAnimationFrame(tick) : callback();
      const time = {
        duration,
        id: requestAnimationFrame(tick)
      };
    },

    getProgress({duration = 1000, id}){
      const progress = duration ? Math.min(this.trackTime(id) / duration, 1) : 1;
      if (progress == 1) performance.clearMarks(id);
      return progress;
    },

    getCurrentValue({from = 0, to, easing}){
        return from + (to - from) * easing;
    },

    easeInCubic(progress){ return Math.pow(progress, 3) },
    easeOutCubic(progress){ return Math.pow(--progress, 3) + 1 },
    easeOutQuartic(progress){ return 1 - Math.pow(--progress, 4) },
    easeOutQuintic(progress){ return Math.pow(--progress, 5) + 1 },
    easeOutExponential(progress){ return progress < 1 ? 1 - Math.pow(2, -10 * progress) : 1 },
    easeOutCircular(progress){ return Math.sqrt(1 - Math.pow(--progress, 2)) },
    easeOutElastic(progress){
      if (progress == 0) return 0;
      if (progress == 1) return 1;
      return Math.pow(2, -10 * progress) * Math.sin((progress - .1) * 5 * Math.PI) + 1;
    },

    easeInOutExponential(progress){
      if (progress == 0) return 0;
      if (progress == 1) return 1;
      return (progress *= 2) < 1
        ? .5 * Math.pow(1024, progress - 1)
        : .5 * (-Math.pow(2, -10 * (progress - 1)) + 2);
    },

    globalTitleAnimation(element, timeduration, timedelay, animeasing = 'ease-out-cubic', yFrom = 0, yTo =0, blurFrom = 0, blurTo = 0, opacityFrom = 0, opacityTo = 1, axis = 'y'){

        const animate = () => {
          const tick = () => {
            const progress = this.getProgress(animation);
            if (progress < 1) requestAnimationFrame(tick);

            // Animation logic
            var easing
            if(animeasing == 'ease-in-cubic'){easing = this.easeInCubic(progress);}
            if(animeasing == 'ease-out-cubic'){easing = this.easeOutCubic(progress);}
            if(animeasing == 'ease-out-exponential'){easing = this.easeOutExponential(progress);}
            const value = this.getCurrentValue({from: yFrom, to: yTo, easing});
            const valueBlur = this.getCurrentValue({from: blurFrom, to: blurTo, easing});
            const value1 = this.getCurrentValue({from: opacityFrom, to: opacityTo, easing});
            if(axis == "y") element.style.transform = `translateY(${value}px)`;
            else element.style.transform = `translateX(${value}px)`;
            element.style.filter = `blur(${valueBlur}px)`;
            element.style.opacity = value1;
          };

          const animation = {
            duration: timeduration,
            id: requestAnimationFrame(tick)
          };
        };

        this.delay(animate, timedelay);
    }


  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
