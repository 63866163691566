<template lang="pug">
  .hero(:class="{ 'dark-bg': fullScreen }")

    HeroBgImage(
      v-if="fullScreen"
      :backgroundImage="backgroundImageBlur"
      :startLoad="loadBlur")

    HeroBgImage(
      v-if="fullScreen"
      :backgroundImage="backgroundImage"
      :startLoad="loadSD"
      @imageLoaded="setAnimIntro")

    HeroBgImage(
      v-if="fullScreen"
      :backgroundImage="backgroundImageHD"
      :startLoad="loadHD")

    ScrollDownText(:whiteBg="fullScreen")

    .flex-that(:class="{ 'white-text': fullScreen }")
      section.titles(
        @mouseover = "mouseOverTitles"
        @mouseleave="mouseLeaveTitles")
        AvatarLarge(
          v-if="avatarOnTitle"
          :imageSource="avatarImageSource"
          :gradientNumber="gradientSourceVar"
          :changeBg="changeAvatavarBg"
          :showSmallControls="showAvatarControls"
          @changeBgProp="changeBgProp")
        h2 {{ heroTitle }}
        p.intro {{ heroSubtitle }}
</template>

<script>
import ScrollDownText from "@/components/ScrollDownText.vue"
import HeroBgImage from "@/components/HeroBgImage.vue"
import AvatarLarge from '@/components/AvatarLarge.vue'

export default {
  name: 'Hero',
  props: {
    heroTitle: String,
    heroSubtitle: String,
    backgroundImage: String,
    backgroundImageHD: String,
    backgroundImageBlur: String,
    fullScreen: Boolean,
    avatarOnTitle: Boolean,
    changeAvatavarBg: Boolean,
    avatarImageSource: Array
  },

  components:  {
    ScrollDownText,
    HeroBgImage,
    AvatarLarge
  },

  data() {
    return {
      loadBlur: false,
      loadSD: false,
      loadHD: false,
      gradientSourceVar: 5,
      showAvatarControls: false
    }
  },

  mounted(){
    if(this.fullScreen){
      //this.loadBlur = true
      setTimeout(() => {
        this.loadBlur = true
      }, 100);
      setTimeout(() => {
        this.loadSD = true
      }, 500);
      setTimeout(() => {
        this.loadHD = true
      }, 1000);
    }
    else{
      this.setAnimIntro()
    }


  },

  methods: {

    setAnimIntro(){
      if(this.avatarOnTitle){
        this.globalTitleAnimation( document.querySelector('.avatar-large'), 1500, 500,'ease-out-cubic', 0, 0, 0, 0, 0, 1 )
        this.globalTitleAnimation( document.querySelector('h2'), 1500, 1500,'ease-out-cubic', -20, 0, 0, 0, 0, 1 )
        this.globalTitleAnimation( document.querySelector('p.intro'), 1500, 2500, 'ease-out-cubic', 0, 0, 0, 0, 0, 1 )
        this.globalTitleAnimation( document.querySelector('.scroll-down'), 1500, 3000, 'ease-out-cubic', 30, 0, 5, 0, 0, 1 )
        this.globalTitleAnimation( document.querySelector('.scroll-down'), 1500, 7200, 'ease-out-exponential', 0, 30, 0, 5, 1, 0 )
      }
      else {
        this.globalTitleAnimation( document.querySelector('h2'), 1500, 0,'ease-out-cubic', -30, 0, 0, 0, 0, 1 )
        this.globalTitleAnimation( document.querySelector('p.intro'), 1500, 500, 'ease-out-cubic', 0, 0, 0, 0, 0, 1 )
        this.globalTitleAnimation( document.querySelector('.scroll-down'), 1500, 2000, 'ease-out-cubic', 30, 0, 5, 0, 0, 1 )
        this.globalTitleAnimation( document.querySelector('.scroll-down'), 1500, 7200, 'ease-out-exponential', 0, 30, 0, 5, 1, 0 )
      }
    },

    changeBgProp(number){
      this.gradientSourceVar = number
    },

    mouseOverTitles(){
      this.showAvatarControls = true
    },
    mouseLeaveTitles(){
      this.showAvatarControls = false
    }

  },

  computed: {
  }

}
</script>


<style scoped lang="scss">

  .hero.dark-bg{
    width: 100vw;
    background-color: rgba(255,255,255,0);
  }

  .imagefade-enter-active,
  .imagefade-leave-active {
      //transition: opacity 1s ease;
   }

  .imagefade-enter,
  .imagefade-leave-active {
      opacity: 0;
  }

  .preloads{
    display: none;
  }

  .top-intro,
  .top-intro-hd,
  .top-intro-blur{
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    position: absolute;

    &.top-intro-blur{ z-index: 1; }
    &.top-intro{ z-index: 2;}
    &.top-intro-hd{ z-index: 3;}
  }

  p.scroll-down{
    @include typography--s-w-lh(1.2rem, 500, 1.6rem);
    color: var(--paragraphColor);
    z-index: 5;
    position: absolute;
    margin: 0;
    top: 96vh;
    width: 84px;
    left: 50%;
    margin-left: -44px;
    transition: opacity 0.2s ease;
  }

  .flex-that{

    width: 100vw;
    height: 100vh;
    text-align: center;
    position: relative;
    z-index:4;
    display: flex;
    justify-content: center;
    align-items: center;

    .titles{
      width: 100%;
      transform: translateY(-1.5rem);

      .avatar-large{
        opacity: 0;
        margin-top: -2rem;
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
        z-index: 3;
        position: relative;
      }

      h2{
        width: 90%;
        opacity: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        position: relative;
        text-align: center;
      }
      p.intro{
        width: 90%;
        opacity: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        position: relative;
        text-align: center;
      }
    }
    &.white-text {
      .titles{
        h2,p.intro{
          color: var(--white);
        }

        p.scroll-down{
          color: var(--white);
        }
      }
    }
  }
</style>
