<template lang='pug'>
.navbar(
  :class="{ 'hidden': !showNavbar, 'show': showNavbar }")

  .logo
    router-link(to="/")
      Logo
  ul.menu
    li
      router-link(to="/") Home
    li
      router-link(to="/work") Work
    li
      router-link(to="/about") About
    li
      router-link(to="/photography") Photography
</template>

<script>

import Logo from '../assets/global/logos/logo-salleedesign.svg';

const OFFSET = 5
var lastScrollPosition = 0
var lastShow = true

export default {
  name: 'Header',

  props: {
    showNavbar: Boolean,
  },

  components:  {
    Logo
  },

  data(){
    return{
    }
  },

  mounted() {
    const viewportMeta = document.createElement("meta")
    viewportMeta.name = "viewport"
    viewportMeta.content = "width=device-width, initial-scale=1"
    document.head.appendChild(viewportMeta)

    /*
    const eventHandler = () => requestAnimationFrame(this.onScroll);
    window.addEventListener('scroll', eventHandler);
    this.$on(`hook:destroyed`, () => {
      window.removeEventListener('scroll', eventHandler);
    });
    */
  },

  methods: {

    //on scroll logic. Up down, black or white icons with full screen.
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      //avoid unnecesssary scroll calculation
      //remove header bg at top no matter what
      if( currentScrollPosition == 0 ){
        this.$emit('setHeaderTransparency', true);
      }
      else{
        //logic here depends on the presence of a top-intro element from Hero to detect an image on background
        if( this.fullImagePage() ){
          //calculate here the height of screen minus header for flipping header from white to black
          var totalHeight = window.innerHeight -this.$el.offsetHeight
          //switch from white bg to transparent bg
          this.$emit('setHeaderTransparency', currentScrollPosition < totalHeight)
        }
        else{
          this.$emit('setHeaderTransparency', false)
        }
      }

      if (currentScrollPosition < 0) { return; }
      if (Math.abs(currentScrollPosition - lastScrollPosition) <= OFFSET) { return; }

      //logic for the up and down state
      var show = lastShow;
      if( currentScrollPosition > lastScrollPosition && lastScrollPosition >= 0 && currentScrollPosition > 0){
        show = false;
      }
      else{ show = true }
      //const show = currentScrollPosition < lastScrollPosition
      if(show != lastShow){
        //alert(currentScrollPosition + ',' + lastScrollPosition)
        this.$emit('showhideBarEvent', show)
      }
      lastShow = show
      lastScrollPosition = currentScrollPosition

    },
  },
}


</script>

<style scoped lang='scss'>

.navbar {
  z-index: 99;
  width: calc(100% - 6rem);
  position: absolute;
  left: 3rem;
  margin-left: 0;
  top: 4rem;
  transition: 0.5s all ease-out;

  display: grid;
  grid-template-columns: 38px auto;
  justify-items: start;
  align-items: center;

  @include mq('700') {
    width: $paragraphWidth;
    margin-left: -$paragraphWidth/2;
    left: 50%;
    margin-left: -$paragraphWidth/2;
  }

  /*
  &.show{
    transition: 0.5s all ease-out;
    transition-property: top, opacity, transform;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &.hidden{
    transform: translate3d(0,-200px,0) !important;
    opacity: 0;
  }
  */


  .logo{

    grid-column: 1;

    a{

      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      position: relative;
      background: var(--logoBgColor);
      border-radius: 10px;
      transition: all 0.2s ease-out;
      box-shadow: var(--logoBoxShadow);

      svg path{
        fill: var(--logoColor)
      }

      &:hover{
        background: var(--logoBgColorHover);
        svg path{
          fill: var(--logoColorHover)
        }
      }
    }

  }

  .menu {
    grid-column: 2;
    justify-self: end;
    display: flex;
    align-self: center;

    li {
      padding-right: 2rem;

      &:last-child{
        padding-right: 0;
      }

      a {
        @include typography--s-w-lh(1.5rem, 500);
        //@include font-smoothing();
        //font-family: $headerTypo;
        color: var(--headerMenuColor);
        &:hover {
          text-decoration: none;
          color: var(--headerMenuColorHover);
        }
      }
    }
  }

  &.fullwidth{
    transform: translate3d(0,0,0);
    //margin: 0;
    //right: 3rem;
    //top: 3rem;
    //left: 3rem;
    //width: calc(100% - 6rem);
    .menu li a{
      color: rgba(255,255,255,0.5);
      &:hover{
        color: rgba(255,255,255,0.9);
      }
    }
  }

}
</style>
