<template lang="pug">
  .photography-detail.wrapper(v-if="routerPage" :key="$route.fullPath")

    Hero(
      :heroTitle="routerPage.title"
      :heroSubtitle="routerPage.subtitle"
      :backgroundImage="getPictureUrl('top-s')"
      :backgroundImageHD="getPictureUrl('top')"
      :backgroundImageBlur="getPictureUrl('top-blur')"
      fullScreen=true)


    .container.photography-detail__container
      template(v-for="block in routerPage.content")

        PhotographyPicSequence(
          v-if="block.type == 'picblock'"
          :start="block.start",
          :end="block.end",
          :singles="block.singles",
          :url="routerPage.url"
        )

        PhotographyTextSequence(
          v-if="block.type == undefined"
          :text="block.text"
        )

    .container
      .sep
      .inside-section.narrow-section(data-aos="fade-in")
        h3.aos-sequence Other Stories
        p.aos-sequence If you made it this far down the page, you might want to explore another photo story. No need to navigate back in your browser! Just pick one below.
      .inside-section(data-aos="fade-in")
        PhotographyList.photolist(v-if="pageList.length" :pageList="pageList" isPhotography=true)
        //h1(v-for="(pic, key) in picMeta" :key="key") {{ key }}: {{ pic["DateTime"] }}


</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import PhotographyPicSequence from '../components/PhotographyPicSequence.vue'
import PhotographyTextSequence from '../components/PhotographyTextSequence.vue'
import PhotographyList from '../components/PhotographyList.vue'
import photographydata from '@/data/photography.json'

export default {
  name: 'PhotographyDetailPage',

  components: {
    Hero,
    PhotographyPicSequence,
    PhotographyTextSequence,
    PhotographyList
  },

  data() {
    return{
      pageList: [],
      routerPage: Object,
      routeTo: ''
    }
  },

  created(){
    document.body.style.setProperty('--logoBgColor', 'rgba(32, 41, 58, 0.75)');
    document.body.style.setProperty('--logoBgColorHover', 'rgba(32, 41, 58, 1)');
    document.body.style.setProperty('--logoBoxShadow', '0 0 0 1px rgba(0, 0, 0, 0.7), 0px 2px 3px rgba(0, 0, 0, 0.3), inset 0px 1px 0px rgba(255, 255, 255, 0.09)');
    document.body.style.setProperty('--headerMenuColor', 'rgba(255,255,255, 0.6)');
    document.body.style.setProperty('--headerMenuColorHover', 'rgba(255,255,255, 1)');
  },

  beforeDestroy(){
    document.body.style.setProperty('--logoBgColor', null);
    document.body.style.setProperty('--logoBgColorHover', null);
    document.body.style.setProperty('--logoBoxShadow', null);
    document.body.style.setProperty('--headerMenuColor', null);
    document.body.style.setProperty('--headerMenuColorHover', null);
  },

  //this happens only if a photo post is clicked whitin a photo story and the component needs to be updated
  //the component won't get mounter again so we use a router guard witht the 'to' destination
  beforeRouteUpdate(to, from, next) {
    this.routeTo = to.params.routerUrl;
    this.routerPage = photographydata.pages.find(this.currentPage)
    this.removeCurrentPost()
    next()
  },

  mounted() {
    this.routeTo = this.$route.params.routerUrl;
    this.routerPage = photographydata.pages.find(this.currentPage)
    this.pageList = [...photographydata.pages]
    this.removeCurrentPost()
  },

  methods: {
    removeCurrentPost(){
      const pageIndex = this.pageList.findIndex(page => page.url === this.routerPage.url)
      this.pageList.splice(pageIndex, 1)
      if(this.pageList[0].id == 1) this.pageList.reverse()
    },
    getPictureUrl(name) {
      const photoname = `photo-${name}`
      const fullUrl = `sallee-design-vue-assets/photography/${this.routerPage.url}`
      return this.urlMaker(photoname, fullUrl, 'jpg')
    },
    currentPage(element) {
      return element.url === this.routeTo;

    }
  }
}
</script>

<style scoped lang="scss">

  .container{
    padding-top: 6rem;
  }
  .photography-detail__container{
    padding-bottom: 2rem;
  }


</style>
