<template lang="pug">
  .pic-sequence
    template(v-if="imageSequence.length"  v-for="(picture,index) in imageSequence")
      AppImageFull(
        :imageData = "getImageData(index, picture)")
      //.cf(v-if="picture.type == 'double' && picture.last")
    //.cf


</template>

<script>
import AppImage from "@/components/AppImage.vue"
import AppImageUnderBox from "@/components/AppImageUnderBox.vue"
import AppImageFull from "@/components/AppImageFull.vue"

export default {
  name: 'PhotographyPicSequence',

  props: {
    start: Number,
    end: Number,
    singles: Array,
    url: String
  },

  components: {
    AppImage,
    AppImageUnderBox,
    AppImageFull
  },

  data() {
    return{
      imageSequence: [],
      imageMetaData: null,
      postUrl: 'sallee-design-vue-assets/photography/',
    }
  },

  mounted() {
    //load the proper json file based on url data ie 'summer-2017'
    this.imageMetaData = require(`@/data/${this.url}.json`)

    let singlesCounter = 0
    let newPicObject

    //we create an array of Pic Objects
    for(let i = this.start; i <= this.end; i++){
      newPicObject = {}
      if(i != this.singles[singlesCounter]){
        newPicObject.double = true
      }
      else{
        singlesCounter++
      }
      this.imageSequence.push(newPicObject)
    }
  },

  methods:{

    getPictureWidth(i) {
      return this.imageMetaData[`photo-${i}`]["width"]
    },

    getPictureHeight(i) {
      return this.imageMetaData[`photo-${i}`]["height"]
    },

    getImageData(index, picObject){
      let imageDataObj = {}
      imageDataObj.width = this.getPictureWidth(index+this.start)
      imageDataObj.height = this.getPictureHeight(index+this.start)
      imageDataObj.double = picObject.double
      imageDataObj.expandable = true
      const photoname = `photo-${index+this.start}`
      const fullUrl = this.postUrl + this.url
      imageDataObj.url = this.urlMaker(photoname, fullUrl, 'jpg')
      return imageDataObj
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .pic-sequence{
    width: 88%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 3rem;
    @include mq('800'){
      display: grid;
      grid-column-gap: 6rem;
      grid-row-gap: 6rem;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      width: 88%;
      max-width: 1320px;
    }
  }

</style>
