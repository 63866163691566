<template lang="pug">
  .about
    .container.about__container
      .inside-section.narrow-section
        .icontitle.introanim-1
          IconUser
          h3.title About me
        .fadetogether.introanim-2
          p.large I make digital products work! I know it’s a really <b>bold</b> statement, but I have the experience and knowledge to back it up.
          a.resume(:href="`${publicPath}sallee-design-vue-assets/resume.zip`") Get my resume
        .fadetogether.introanim-3
          .stats
            .item
              IconLocation
              p Bend, Oregon
            .item
              IconMessages
              p English, French, Spanish
            .item
              IconCertificate
              p Master Degree
        .fadetogether.introanim-4
          img(:src="getImgUrl('mugshot')")
          p I have been making digital product for 14+ years. Yes, that is a lot of pixels. This experience has helped me understand the problems that prevent creative solutions from achieving business objectives. I have a strong belief that approaching deliverables with a UX and creative focus can help bridge those gaps and produce work that is efficient, pleasing, and ultimately elevates the user experience with your brand.

      .inside-section.narrow-section.introanim-5
        TitleWithLargeIcon(title="International Path" imageName="international")
        p I am French, living in the USA since february 2012, located in Bend, Oregon (previously San Francisco for 9 years). Like all French people I live off baguettes, champagne & foie gras evidently! I also say <b>ohlala</b>
        p I traveled a very atypical path, both personally and professionally. I was born in Cameroon and raised in 6 different countries and 3 continents. I speak <b>English</b>, <b>French</b> and <b>Spanish</b> fluently. I have been good at math and science since my childhood, studying and obtaining a <b>Master’s Degree</b> in Electrical Engineering. After working in that field for a couple of years, I decided to learn design on my own and have been working as a product designer and front end developer since July, 2007.
        p One of the greatest strenghs about being a designer with an engineering background is that not only I can handle the graphical aspects of a project, but I can also fully understand, participate and even lead the technical aspects of those same projects.
      .inside-section.map
        img(:src="getImgUrl('map')")

      .inside-section.narrow-section
        TitleWithLargeIcon(title="Get in Touch" imageName="mail")
        p
          |I am currently open to design opportunities. If you want, you can view my <a href="https://www.linkedin.com/in/jeremy-sallée-7504355/" target="_black">LinkedIn</a> profile or download my&nbsp
          a(:href="`${publicPath}sallee-design-vue-assets/resume.zip`") resume
          |. I also showcase designs on <a href="http://dribbble.com/salleedesign" target="_blank">Dribbble</a>, casual pictures on <a target="_blank" href="http://instagram.com/jeremy_sallee">Instagram</a> and sometimes post on my <a target="_blank" href="https://twitter.com/salleedesign">Twitter</a> account.
        p If you have any questions, feedback, or just want to say hello, don't hesitate to <a href="mailto:jeremy.sallee@gmail.com">send me an email</a>.

</template>

<script>

import IconUser from '../assets/global/icons/Icon-User.svg';
import IconMessages from '../assets/global/icons/Icon-Messages.svg';
import IconLocation from '../assets/global/icons/Icon-Location.svg';
import IconCertificate from '../assets/global/icons/Icon-Certificate.svg';
import IconWorld from '../assets/global/icons/Icon-World.svg';
import TitleWithLargeIcon from '@/components/TitleWithLargeIcon.vue'


export default {
  name: "about",
  components: {
    IconUser,
    IconMessages,
    IconCertificate,
    IconLocation,
    IconWorld,
    TitleWithLargeIcon
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      markdown: undefined
    }
  },
  mounted(){
    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 300,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 600,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-3'), 1000, 900,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1200,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-5'), 1000, 1500,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
  },
  methods: {
    getImgUrl(name) {
        return require(`@/assets/about/${name}.png`)
    }
  }
}
</script>

<style scoped lang="scss">

  .about{
    &__container{


      .map{
        padding-top: 20rem;
        padding-bottom: 20rem;
        background-color: #1A316D;
        img{
          width: 90%;
          max-width: 1069px;
        }
      }

      .resume{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 12px 1px 12px;
        height: 36px;

        background: #635BFF;
        border-radius: 8px;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $white;
        transition: background 0.3s ease-out;
        &:hover{
          background: $dark-1;
        }
      }

    }
  }


</style>
