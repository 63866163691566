<template lang='pug'>
  .photography
    .container.photography__container
      .inside-section.narrow-section
        .icontitle.introanim-1
          IconShutter
          h3.title {{ photographyTitle }}
        p.large.introanim-2 {{ photographySubtitle }}
        .fadetogether.introanim-3
          p.intro But what I enjoy the most is candid photography, framing small feelings and atmospheres in order to tell stories. These past years I have been shooting and developing my own film which has been an even more rewarding experience.
          .stats
            .item
              IconMagazine
              p 9 photo stories
            .item
              IconImages
              p 690 total pictures
            .item
              IconHardDrive
              p 510MB of imagery
      PhotographyList.photolist.introanim-4(v-if="pages.length" :pageList="pages" isPhotography=true)
</template>

<script>

import PhotographyList from '@/components/PhotographyList.vue'
import photographydata from '@/data/photography.json'
import IconShutter from '../assets/global/icons/Icon-Camera.svg';
import IconImages from '../assets/global/icons/Icon-Images.svg';
import IconMagazine from '../assets/global/icons/Icon-Magazine.svg';
import IconHardDrive from '../assets/global/icons/Icon-HardDrive.svg';

export default {
  name: 'photography',

  components: {
    PhotographyList,
    IconShutter,
    IconImages,
    IconMagazine,
    IconHardDrive
  },

  data() {
    return {
      pages: photographydata.pages,
      test: 'fullscreen',
      photographyTitle: 'Photography',
      photographySubtitle: 'I have been practicing photography for many years. Getting involved and learning a variety of shooting styles and techniques.',
      gradientNumberSource: 1
    }
  },

  mounted() {
    if(this.pages[0].id == 1) this.pages.reverse()

    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 0,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 500, 'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector(".introanim-3"), 1000, 1000, 'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1500,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
  },

  methods: {
    changeBgProp(number){
      this.gradientNumberSource = number
    }
  }
}

</script>

<style scoped lang="scss">
  .photography{

    &__container{

      row-gap: 3rem;

      .icontitle{

        opacity: 0;

        svg,h3{
          flex: none;
        }
      }
    }
  }
</style>
