<template lang="pug">
  .image-item
    .item
      .itemImgContainer(@click="goTodetail(page.url, page)")
        .imgContainer(:style="{ backgroundImage: `url(${getPictureUrl(page.thumbnail)})` }")
          .cover
    .text-block
      .linkcontainer
        img(:src="getAvatarUrl(page.url)" v-if="!isPhotography")
        a(@click="goTodetail(page.url, page)") {{ page.title }}
      .image-counter
        IconImages(v-if="isPhotography")
        IconCalendar(v-if="!isPhotography")
        .pff {{ page.total }}

</template>

<script>

import IconShutter from '../assets/global/icons/Icon-Shutter.svg';
import IconImages from '../assets/global/icons/Icon-Images.svg';
import IconCalendar from '../assets/global/icons/Icon-Calendar.svg';

export default {
  name: 'PhotographyListItem',

  props: {
    page: Object,
    isPhotography: Boolean
  },

  components:  {
    IconShutter,
    IconImages,
    IconCalendar
  },

  data() {
    return{}
  },

  methods:{
    goTodetail(pUrl, pPa) {
      if(this.isPhotography){
          this.$router.push({name:'PhotographyDetailPage', params:{routerUrl:pUrl, page: pPa}})
      }
      else{
        //here we will redirect to the work pages instead of the photography pages /work/nutanix
        var componentName = this.camelCase(pUrl)
        componentName = this.capitalizeFirstLetter(componentName)
        this.$router.push({name: componentName})
      }
    },

    getPictureUrl() {
      const photoname = `thumbnail`
      var fullUrl = ''
      if( this.isPhotography){
        fullUrl = `sallee-design-vue-assets/photography/${this.page.url}`
      }
      else{
        fullUrl = `sallee-design-vue-assets/work/${this.page.url}`
      }
      return this.urlMaker(photoname, fullUrl, 'jpg')
    },

    getAvatarUrl() {
      const photoname = `avatar`
      var fullUrl = `sallee-design-vue-assets/work/${this.page.url}`
      return this.urlMaker(photoname, fullUrl, 'png')
    },

    camelCase(string) {
        return string.replace( /-([a-z])/ig, function( all, letter ) {
            return letter.toUpperCase();
        });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .item{
    padding: 0;
    background: transparent;
    border: none;
    aspect-ratio: 4/3;
    border-radius: 1rem;
    overflow: hidden;

    @include mq('900'){
      margin-bottom: 0;
    }

    .itemImgContainer{
      //margin-bottom: 2.5rem;
      width: 100%;
      height: 100%;

      cursor: pointer;

      .imgContainer{
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        transition: 0.4s transform ease-out;

        .cover{
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          transition: 0.2s background ease-in;
        }

      }



      &:hover{
        .imgContainer{
          transform: scale(1.05);
        }
        .imgContainer{
        }
        .cover{
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }


  .text-block{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    margin-top: 1rem;
    width: 100%;

    .linkcontainer{

      display: flex;
      flex-direction: row;
      align-items: center;

      flex: none;
      order: 0;
      flex-grow: 1;

      column-gap: 0.5rem;

      a{
        color: var(--paragraphColor);
        @include typography--s-w-lh(1.4rem, 500, 2.2rem);
        text-align: left;
        cursor: pointer;
        &:hover{
          color: var(--linkColor);
        }
      }

      img{
        width: 2.4rem;
        height: 2.4rem;
        margin: 0;
        border-radius: 10px;
      }

    }
    .image-counter{
      @include typography--s-w-lh(1.2rem, 500, 1.6rem);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;

      flex: none;
      order: 1;
      flex-grow: 0;

      column-gap: 1rem;

    }
  }

</style>
