import { render, staticRenderFns } from "./PhotographyDetailPage.vue?vue&type=template&id=d13c53ba&scoped=true&lang=pug&"
import script from "./PhotographyDetailPage.vue?vue&type=script&lang=js&"
export * from "./PhotographyDetailPage.vue?vue&type=script&lang=js&"
import style0 from "./PhotographyDetailPage.vue?vue&type=style&index=0&id=d13c53ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d13c53ba",
  null
  
)

export default component.exports