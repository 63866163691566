<template lang="pug">
  #app
    //loader
    .loader-page(:class="{ 'remove-loader': loaderAnimDone }")
      h3 loading...
    .main-app(:class="{ 'loader-removed': !preventMainScroll }")
      //header
      Header(:showNavbar="showNavbar"
             :fullImage="fullImage"
             @showhideBarEvent="showhideBar"
             @setHeaderTransparency="headerTransparency")
      //this is main
      .transition-frame
      transition(name='fade',
                 mode='out-in',
                 @enter="enterTransition",
                 @before-enter="beforeEnter",
                 @leave="leaveTransition",
                 appear)
        router-view
      Footer(v-if="showFooter")
</template>

<script>



import router from './router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

var fadeHeaderBecauseOfScroll = false

router.beforeEach((to, from, next) => {
  next()
})

router.afterEach(() => {
})

export default {
  name: 'App',

  data() {
    return {
      photography: this.$photography,
      showNavbar: true,
      showFooter: true,
      fullImage: false,
      preventMainScroll: false, //fake loader stuff I was doing
      loaderAnimDone: true, //fake loader stuff I was doing
    }
  },

  components: {
    Header,
    Footer,
  },

  mounted() {

    //init stuff
    this.setHeaderColor()
    this.preventScrollHistory()
    this.setAosProgressive()

    //TOTALLY FAKE LOADER DON'T USE IT IF YOU DON'T NEED IT!
    /*
    setTimeout(() => {
      TweenMax.fromTo(
        document.querySelector(".loader-page"),0.5,{
          opacity: 1,
        },{
          opacity: 0,
          ease: Power1.easeIn,
          onComplete: this.removeLoader
        }
      );
      this.preventMainScroll = false
    }, 200);
    */
  },

  methods: {

    removeLoader(){
      this.loaderAnimDone = true
    },

    preventScrollHistory() {
      if ('scrollRestoration' in history) {
        //prevent browser to scroll on refresh or back button. (stupid browser)
        history.scrollRestoration = 'manual';
      }
    },

    setAosProgressive() {
      document.addEventListener('aos:in', ({ detail }) => {
        this.asyncLoop(
          detail.querySelectorAll('.aos-sequence'),
          function(item){
            item.classList.add('visible');
          }
        );
      });
      document.addEventListener('aos:out', ({ detail }) => {
        detail.querySelectorAll('.aos-sequence').forEach((el) => {
          el.classList.remove('visible');
        });
      });
    },

    asyncLoop(arr, fn, cb) {
      const len = arr.length;
      let count = 0;

      function recursion() {
        if (count < len){
          fn(arr[count], count, arr);
          setTimeout(recursion, 250);
        } else if (cb) {
          cb();
        }
        count++;
      }
      setTimeout(recursion, 0);
    },

    setFooter(){
      if( this.$route.name == 'Stealth' ){
        this.showFooter = false
      }
      else{
        this.showFooter = true
      }
    },

    resetFooter(){
      if( this.showFooter ) document.querySelector(".footer").style.opacity = 1
    },

    setHeaderColor() {
      this.fullImage = this.fullImagePage()
    },

    resetTransition(){
      this.setFooter()
    },

    showhideBar(show) {
      this.showNavbar = show
    },

    headerTransparency(transparent){
      this.transparentBg = transparent
    },


    enterTransition(el, done) {
      //el.style.opacity = 1;
      //this.globalTitleAnimation( document.querySelector('.avatar-large'), 1500, 500,'ease-out-cubic', 0, 0, 0, 0, 0, 1 )
      //setTimeout(() => {  done() }, 2000);
      done()
    },

    leaveTransition(el, done) {
      //when transitionning, check if we are below the fold (header is not visible)
      fadeHeaderBecauseOfScroll = false
      var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
      if(scrollTop > 150){
        fadeHeaderBecauseOfScroll = true
        //hide the nav for transition in
        document.querySelector('.navbar').style.opacity = 0
      }

      //this.setHeaderColor()
      //el.style.opacity = 0;

      //fade out main & footer
      this.globalTitleAnimation( el, 600, 0,'ease-out-cubic', 0, 0, 0, 0, 1, 0 )
      this.globalTitleAnimation( document.querySelector('.footer'), 300, 0,'ease-out-cubic', 0, 0, 0, 0, 1, 0 )
      //fade out footer

      /*
      if( this.showFooter ){
          this.globalTitleAnimation( document.querySelector('.footer'), 300, 0,'ease-out-cubic', 0, 0, 0, 0, 1, 0 )
      }
      */
      //document.querySelector('.navbar').style.top = '-38px'

      //the app will wait for the end of the time out to continue the textSequence//VERY USEFULL
      setTimeout(() => {
        done(
          //show the footer back when the fade is over
          this.resetFooter()
        ) }, 650);
    },

    beforeEnter: function() {
      //check if we need to fade the header back in
      if( fadeHeaderBecauseOfScroll){
        this.globalTitleAnimation( document.querySelector('.navbar'), 300, 0,'ease-in-cubic', 30, 0, 0, 0, 0, 1 )
      }
      //scroll main to top for new content
      window.scrollTo(0,0)
      //this.setHeaderColor()
      //this.setFooter()
    },

  }

};

</script>

<style lang="scss">

</style>
