<template lang='pug'>
.footer
  .content
    .logo
      Logo
    .menu
      router-link(to="/") Home
      router-link(to="/work") Work
      router-link(to="/about") About
      router-link(to="/photography") Photography
    .social
      a(href="mailto:jeremy.sallee@gmail.com")
        IconMail
      a(href="http://dribbble.com/salleedesign" target="_blank")
        IconDribbble
      a(href="http://instagram.com/jeremy_sallee" target="_blank")
        IconInstagram
      a(href="https://www.linkedin.com/in/jeremy-sallée-7504355/" target="_blank")
        IconLinkedin
  p.copyright All content and images Copyright © 2022 Jeremy Sallee


</template>

<script>

import Logo from '../assets/global/logos/logo-salleedesign.svg';
import IconMail from '../assets/global/icons/Icon-Email.svg';
import IconLinkedin from '../assets/global/icons/Icon-Linkedin.svg';
import IconDribbble from '../assets/global/icons/Icon-Dribbble.svg';
import IconInstagram from '../assets/global/icons/Icon-Instagram.svg';

export default {
  name: 'Footer',

  props: {
  },

  components:  {
    Logo,
    IconMail,
    IconLinkedin,
    IconDribbble,
    IconInstagram
  },

  data(){
    return{
    }
  },

  mounted() {
  },

  methods: {
  },
}


</script>

<style scoped lang='scss'>

  .forceshow{
    opacity: 1;
  }
  .footer{
    @include typography--s-w-lh(1.2rem, 500, 1.6rem);
    @include font-smoothing();

    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 480px;
      box-shadow: var(--footerBoxShadow);
      background-color: var(--footerBgColor);
      row-gap: 2rem;

      .logo{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;

        width: 38px;
        height: 38px;

        /* white */

        background: var(--footerLogoBgColor);
        box-shadow: var(--footerLogoBoxShadow);
        border-radius: 8px;
        svg path{
          fill: var(--footerLogoColor);
        }

      }
      .menu{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        column-gap: 1.5rem;
      }
      a{
        color: var(--footerMenuColor);
        transition: color 0.4s ease-out;
        &:hover{
          color: var(--footerMenuColorHover);
        }
      }

      .social{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        column-gap: 2rem;
        a{
          .strong-highlight{
            fill: var(--footerIconsColor1);
          }
          .soft-highlight{
            fill: var(--footerIconsColor2);
          }
          .white-highlight{
            fill: var(--footerIconsColor3);
          }
        }
        a:hover{
          .strong-highlight{
            fill: var(--footerIconsColor1Hover);
          }
          .soft-highlight{
            fill: var(--footerIconsColor2Hover);
          }
          .white-highlight{
            fill: var(--footerIconsColor3Hover);
          }
        }
      }
    }
    .copyright{
      padding-bottom: 3rem;
      font-weight: 400;
      color: var(--footerCopyrightColor);
    }
  }

</style>
