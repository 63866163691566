<template lang='pug'>
  .casestudies
    .container.casestudies__container
      .inside-section.narrow-section
        .icontitle.introanim-1
          IconWork
          h3.title My Work
        p.large.introanim-2 I have been involved in many projects during my career and have helped companies define long lasting, sucessful products.
        .fadetogether.introanim-3
          p
            |In 2012 I moved to San Francisco for my first startup adventure in the Sillicon Valley working for&nbsp
            router-link(to="/work/telly") Telly
            |. In 2013 I joined&nbsp
            router-link(to="/work/nutanix-work") Nutanix
            |&nbspas their first product designer working on the main UI. In 2016 Nutanix went public and has today a $6B market valuation.
          p
            |In 2020, I joined a&nbsp
            router-link(to="/work/stealth") stealth
            |&nbspstartup as their founding designer to build a brand new product from the ground up.


        .stats.introanim-4
          .item
            IconMeeting
            p 5,945 Meetings
          .item
            IconPixel
            p +1 Billion Pixels
          .item
            IconCoffee
            p 18,564 Cups of Coffee
      PhotographyList.introanim-5.photolist(v-if="pages.length" :pageList="pages" isPhotography=false)

</template>

<script>

import PhotographyList from '@/components/PhotographyList.vue'
import IconWork from '../assets/global/icons/Icon-Mouse.svg';
import IconMeeting from '../assets/global/icons/Icon-Meeting.svg';
import IconPixel from '../assets/global/icons/Icon-Pixels.svg';
import IconCoffee from '../assets/global/icons/Icon-Coffee.svg';
import workdata from '@/data/work.json'

export default {
  name: 'work',

  components: {
    PhotographyList,
    IconWork,
    IconMeeting,
    IconPixel,
    IconCoffee
  },

  data() {
    return {
      pages: workdata.pages,
    }
  },

  mounted() {

    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 300,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 600,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-3'), 1000, 900,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1200,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-5'), 1000, 1500,'ease-out-cubic', 30, 0, 0, 0, 0, 1)

  },

  methods: {

  }
}

</script>

<style scoped lang="scss">
  .casestudies{

    &__container{

    }
  }
</style>
