<template lang="pug">
  div(:class="{ 'full-width-pic': imageData.double == undefined, 'half-pic': imageData.double }")
    AppImage(
      :expandable="imageData.expandable"
      :width="imageData.width"
      :height="imageData.height"
      :lazy-src="imageData.url"
      @loadedImageEvent="hideUnderImage"
      :class="{ 'image-top-loaded': loaded }")
    AppImageUnderBox.underimage(
      :width="imageData.width"
      :height="imageData.height"
      :class="{ 'image-loaded': loaded }")
</template>

<script>
import AppImage from "@/components/AppImage.vue"
import AppImageUnderBox from "@/components/AppImageUnderBox.vue"
export default {
  name: 'AppImageFull',

  props: {
    imageData: Object
  },

  data() {
    return {
      loaded: false,
    };
  },

  components: {
    AppImage,
    AppImageUnderBox
  },

  methods: {

    hideUnderImage() {
      this.loaded = true;
    }

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .full-width-pic{
    grid-column: 1 / span 2;
  }

  .full-width-pic, .half-pic {
    width: 100%;
    justify-self: center;
  }
  .underimage{
    transition: opacity 0.3s ease-out
  }
  .image-loaded{
    opacity: 0;
    z-index: 0;
  }
  .expandable-image{
    opacity: 0;
    transition: opacity 0.3s easeOut;
    &.image-top-loaded{
      opacity: 1;
    }
  }
  
  /*
  .full-width-pic, .half-pic {
    //margin-top: 3rem;
    margin-bottom: 6rem;
  }

  .half-pic{
    width: 48%;
    margin-right: 0;
    float: left;
    &.even{
      margin-right: 4%;
    }
  }
  */
</style>
