<template lang="pug">
  .telly
    .container.telly
      .inside-section.narrow-section
        h1.introanim-1 Telly
        p.large.introanim-2 In 2011 I joined Telly as their founding designer for my first San Francisco startup adventure
        .fadetogether.introanim-3
          p This was my first “real” job as a designer. In my previous work I was a software engineer transitioning to design on my own. I was very nervous and excited. This was also the begining of my 10 year stay in the USA.
          p Telly (previously Twidvid) was a fast moving company that was trying to disrupt a very competitive market, video discovery. At the time Youtube was lacking discoverability, Netflix and Instagram Reels did not exist. The future would define Vine as the clear winner, which was then acquired and closed by Twitter in 2016.


      .inside-section.narrow-section.introanim-4
        TitleWithLargeIcon(title="Web and Mobile" imageName="video")
        p The company was building 4 different platforms: an iphone, ipad, and android apps, and a web platform. There was no marketing website or branding either.
        p The process was very straight forward. There was clear tasks to tackle since there was a lot of work. I was sitting right by the side of the founders so we could brainstorm ideas, layouts and new features at any time. We had daily scrum meetings.
        p I did a lot that year. Web and Mobile product design, branding, marketing design, video creation and edition, photography, code... I entirely coded the navigation of the web UI and was be very involved with the mobile developers in order to provide the right assets for their needs.
        p Rather than showing you pixelated Photoshop images, I decided to redesign the screen from 2012 on Figma for practice.

      .inside-section
        .photo1
          img(:src="getPublicImgUrl('photo-1')")

      .inside-section.narrow-section
        p These where the times of high Skeumorphism. Everything was embossed, beveled and shadowed. Here is the design of the video feed. At the time the goal was to make a video feed where people could discover and save videos.

      .inside-section
        .photo2
          img(:src="getPublicImgUrl('photo-2')")

      .inside-section.narrow-section
        p The web UI was by far the most complex of all the platforms. Here are all the elements coming together:

      .inside-section
        .gradient
        .photo3
          img(:src="getPublicImgUrl('photo-3')")

      .inside-section.narrow-section
        TitleWithLargeIcon(title="iPhone Native Application" imageName="iphone")
        p The iPhone native design was the most fun to make during that experience. At the time skeumorphism was king and Telly mobile designs are a statement of that. Especially the video recording flow. I made filter covers, CD covers and a very realistic record button that would turn and glow while recording.
        p Here are a few design pieces from back then:

      .inside-section
        .iphone-screens
          img(:src="getPublicImgUrl('photo-4')")
          img(:src="getPublicImgUrl('photo-5')")
          img(:src="getPublicImgUrl('photo-6')")

      .inside-section
        .photo7
          img(:src="getPublicImgUrl('photo-7')")

      .inside-section.narrow-section
        p The app icon also went through a lot of iterations. At first we took freelancers to help out, but I quickly realize I was able to make them myself. Also the fast iterations on them didn’t really give me an option.
        p These icons would stand out way too much on today’s phone dashboards:

      .inside-section
        .photo7
          img(:src="getPublicImgUrl('photo-8')")




</template>

<script>
// @ is an alias to /src
import TitleWithLargeIcon from '@/components/TitleWithLargeIcon.vue'

export default {
  name: 'Telly',

  components: {
    TitleWithLargeIcon
  },

  data() {
    return{
    }
  },

  created(){
    document.body.style.setProperty('--bodyBg', '#F5F4EF');
    document.body.style.setProperty('--paragraphColor', '#666161');
    document.body.style.setProperty('--headersColor', '#2D2C2A');
    document.body.style.setProperty('--legendColor', 'hsla(0,0,60%,0.3)');
  },

  beforeDestroy(){
    document.body.style.setProperty('--bodyBg', null);
    document.body.style.setProperty('--paragraphColor', null);
    document.body.style.setProperty('--headersColor', null);
    document.body.style.setProperty('--legendColor', null);
  },


  mounted() {
    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 300,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 600,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-3'), 1000, 900,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1200,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
  },

  methods: {


    getImgUrl(name) {
        return require(`@/assets/stealth/${name}`)
    },

    getPublicImgUrl(name, ext = 'png'){
      return this.urlMaker(name, 'sallee-design-vue-assets/work/telly', ext)
    }
  }
}
</script>

<style scoped lang="scss">

  .narrow-section{
    position: relative;
    z-index: 3;
  }

  .photo1{
    width: 90%;
    max-width: 603px;
  }

  .photo2{
    width: 90%;
    max-width: 606px;
  }

  .photo3{
    width: 90%;
    max-width: 1276px;
    position: relative;
    z-index: 2;
  }

  .gradient{
    display: block;
    width: 100%;
    transform: translateY(-10rem);
    @include mq('900'){
      transform: translateY(-14rem);
    }
    background: linear-gradient(to bottom,
                                #F5F4EF,
                                5%,
                                #EEEAD6,
                                15%,
                                #9BDCE0,
                                56%,
                                #DDDDF0,
                                72%,
                                #F3CDEB
                                84%,
                                #F4DDDD,
                                92%,
                                #F5F4EF);

    aspect-ratio: 12/17;
    position: absolute;
    z-index: 1;
    @include mq('1460'){
      aspect-ratio: none;
      height: 1900px;
    }
  }



  .photo7{
    width: 90%;
    max-width: 1080px;
  }

  .iphone-screens{
    width: 90%;
    @include mq('600'){
      display: grid;
      grid-column-gap: 6rem;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      max-width: 1080px;
    }

    img{
      filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.11)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.079074)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0655718)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.055)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0444282)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.030926));
      margin-bottom: 6rem;
    }
  }


</style>
