<template lang='pug'>
  p.scroll-down(:class="{ 'scrolled': scrolled, 'white-bg': whiteBg }") Scroll Down
</template>

<script>

export default {
  name: 'ScrollDownText',

  props: {
    whiteBg: Boolean
  },

  data(){
    return{
      scrolled: false
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },

  methods: {

    onScroll() {
      if(this.$el.style.opacity == 1){
        this.$el.style.opacity = null
        //set to scrolled so we can fade it out
        this.scrolled = true
        window.removeEventListener('scroll', this.onScroll);
      }

    },
  }
}

</script>

<style scoped lang='scss'>

  p{
    opacity: 0;
  }

  .scroll-down{

    &.scrolled{
      opacity: 0;
      transition: 0.5s opacity ease;
    }
    &.white-bg{
      color: $white;
      padding: 6px 0px 6px;
      text-align: center;
      font-style: normal;
      background: rgba(37, 39, 51, 0.2);
      border-radius: 55px;
      @include typography--s-w-lh(1.2rem, 500, 1.6rem);
    }
  }




</style>
