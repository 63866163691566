<template lang="pug">
  .photo-list(:class="{ 'bigger': !isPhotography }")
    PhotographyListItem(:isPhotography="isPhotography"
    v-if="pageList.length" v-for="(page,index) in pageList" :key="index", :page='page')
</template>

<script>
import PhotographyListItem from '@/components/PhotographyListItem.vue'

export default {
  name: 'PhotographyList',

  props: {
    pageList: Array,
    baseURL: String,
    isPhotography: Boolean
  },

  components: {
    PhotographyListItem
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .photo-list{
    display: grid;
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    @include mq('700'){
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq('1460'){
      grid-template-columns: repeat(3, 1fr);
    }

    &.bigger{
      @include mq('700'){
        grid-template-columns: repeat(2, 1fr);
      }
      @include mq('1460'){
        grid-template-columns: repeat(2, 1fr);
      }
      max-width: 1240px !important;
    }
  }
</style>
