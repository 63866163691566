<template lang="pug">
  .avatar-large(:class="{ 'gridit': !smallAvatar && changeBg }")
    .outterbox(:class="{ 'small': smallAvatar }")
      .avatar(:style="getStyle(gradientNumber)")
        .imgWrapper(:style="getSliderWidth()")
          img(
            v-for="(image,index) in imageSource"
            :src="getImgUrl(index)")
    .dropdown-button(
      ref="dropdownref"
      :class="{ 'showControls': showSmallControls, 'disableOverControls': showColorPicker }"
      @click="openDropDown")
      Controls
    .avatar-switcher(v-if="!smallAvatar && changeBg")
      .dropdown-container(
        :class="{ 'showClass': showColorPicker }"
        v-outside-click="{exclude: ['dropdownref'],handler: closeDropDown}")
        .dropdown-top
          p Avatar
          .avatar-controls
            Chevron.chevron.left(
              :class="{ 'disabled': disableLeftChevron }"
              @click="leftAvatar"
              )
            Chevron.chevron.right(
              :class="{ 'disabled': disableRightChevron }"
              @click="rightAvatar"
              )

        .color-picker-box
          p Background
          .color-list
            .color(
              v-for="(color,index) in gradientsArray"
              :style="getStyle(index)"
              :class="{ 'selected': selectedBgNumber == index }"
              @mouseover = "changeBgColor(index)"
              @mouseleave="resetBgColor"
              @click="newBgColor(index)"
              :key='index')



</template>

<script>

import Controls from '../assets/home/avatar-controls.svg';
import Chevron from '../assets/home/chevron.svg';

export default {
  name: 'AvatarLarge',
  props: {
    imageSource: Array,
    gradientNumber: Number,
    smallAvatar: Boolean,
    changeBg: Boolean,
    showSmallControls: Boolean,
  },

  components:  {
    Controls,
    Chevron
  },

  data() {
    return {

      showColorPicker: false,
      selectedBgNumber: 0,
      currentAvatar: 0,
      disableLeftChevron: true,
      disableRightChevron: false,

      gradientsArray : [
        {
          name: 'red',
          background: 'linear-gradient(136.78deg, #FFD7B2 10.55%, #FCA5BF 85.16%)'
        },
        {
          name: 'blue',
          background: 'linear-gradient(136.78deg, #8CCFFF 10.55%, #95E6C9 85.16%)'
        },
        {
          name: 'green',
          background: 'linear-gradient(136.78deg, #FFF280 10.55%, #A5E1A4 85.16%)'
        },
        {
          name: 'pink',
          background: 'linear-gradient(136.78deg, #944DCC 10.55%, #F1A6F2 85.16%)'
        },
        {
          name: 'gray',
          background: 'linear-gradient(136.78deg, #E7EBF0 10.55%, #C0C8D0 85.16%)'
        },
        {
          name: 'anim',
          background: 'linear-gradient(136.78deg, #FFD7B2 10.55%, #FCA5BF 26.09%, #FFE380 41.63%, #95E5CA 56.79%, #8DD0FC 70.78%, #C3CAD3 85.16%)',
          backgroundsize: '500% 500%',
          animation: 'gradientKeyframes 25s ease infinite'
        }

      ],

    }
  },

  mounted(){
    this.selectedBgNumber = this.gradientNumber;
    this.openDropDown();
  },

  methods: {
    changeBgColor(index) {
      this.$emit('changeBgProp', index);
    },
    resetBgColor(){
      this.$emit('changeBgProp', this.selectedBgNumber);
    },
    newBgColor(index){
      this.selectedBgNumber = index;
    },
    getImgUrl(index) {
        return require(`@/assets/home/${this.imageSource[index]}.png`)
    },

    getSliderWidth(){
      var style = { width: `${this.imageSource.length*10.8}rem` }
      return style
    },

    getStyle(value) {
      var myObj = this.gradientsArray[value]
      var style
      if(myObj.animation != undefined){
        style = {
          background: myObj.background,
          backgroundSize: myObj.backgroundsize,
          animation: myObj.animation
        }
      }
      else{
        style = { background: myObj.background }
      }
      return style;
    },
    openDropDown(){
        if(this.showColorPicker ==  false){ this.showColorPicker = true; }
    },
    closeDropDown(){
      //if(this.showColorPicker ==  true){ this.showColorPicker = false; }
    },
    leftAvatar(){
      if( this.currentAvatar > 0 )
      {
        this.currentAvatar--;
        this.resetChevrons()
        if(this.currentAvatar == 0){ this.disableLeftChevron = true }
        this.slideAvatars()
      }
    },
    rightAvatar(){
      if( this.currentAvatar < this.imageSource.length-1 ){
        this.currentAvatar++;
        this.resetChevrons()
        if(this.currentAvatar == this.imageSource.length-1){ this.disableRightChevron = true }
        this.slideAvatars()
      }
    },
    resetChevrons(){
      this.disableLeftChevron = false
      this.disableRightChevron = false
    },
    slideAvatars(){
      const slideX = -1*this.currentAvatar*10.8
      document.querySelector('.imgWrapper').style.transform = `translateX(${slideX}rem)`
    }
  },

  computed: {
  },

  watch: {
  }


}
</script>


<style scoped lang="scss">

  //main
  .avatar-large{
    user-select: none;

  }

  .gridit{
    display: grid;
    grid-template-columns: 12.8rem 1.3rem 12.4rem;
    align-items: center;
    column-gap: 3rem;
  }

  //the avatar
  .outterbox{
    margin: 0 auto;
    background: #fff;
    height: 10.8rem;
    width: 10.8rem;
    padding: 1rem;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.08);
    border-radius: 2.6rem;
    z-index: 2;
    .avatar{
      border-radius: 2rem;
      width: 10.8rem;
      height: 10.8rem;
      overflow: hidden;
      background-position: 50% 50%;
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.08), inset 0px 0px 7px -2px rgba(0, 0, 0, 0.25);
      .imgWrapper{
        display: block;
        height: 10.8rem;
        transform: translateX(0);
      }
      img{
        height: 10.8rem;
      }
    }
    &.small{
      box-shadow: 0px 7px 15px -10px rgba(50, 50, 93, 0.40), 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.08);
      height: 8.2rem;
      width: 8.2rem;
      padding: 0.8rem;
      .avatar, img{

        width: 8.2rem;
        height: 8.2rem;
      }
    }
  }

  //this should be refactored...
  .avatar-switcher{

  }

  //the triple dot svg
  .dropdown-button{
    //cursor: pointer;
    //display: none;
    //transform: translate(-4rem, 0);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease;
    @include mq('600'){
      display: block;
    }
    &.showControls{
      transform: translate(0, 0);
      opacity: 1;
    }
    svg g{
      transition: all 0.4s ease;
      fill: #FF0000;
      opacity: 0.3;
    }
    &:hover{
      svg g{
        opacity: 0.3;
      }
    }
    &.disableOverControls{
      transform: translate(0, 0);
      opacity: 1;
      svg g{
        opacity: 0.3;
      }
    }
  }

  .dropdown-container{
    background: #fff;
    width: 12.4rem;
    display: grid;
    grid-template-columns: 4rem auto;
    box-shadow: 0px 0px 0px 1px rgba(215, 124, 126, 0.25), 0px 2px 3px rgba(215, 124, 126, 0.46);
    display: none;
    border-radius: 1.5rem;

    &.showClass{
      display: inherit;
    }
  }

  .dropdown-top{
    box-shadow: 0px 1px 0px rgba(153, 165, 177, 0.25);
    display: grid;
    padding: 2rem;
    grid-template-columns: auto 3.4rem;
    p{
      justify-self: left;
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
  //left right controls
  .avatar-controls{
    background: #C9CFD7;
    border-radius: 36px;
    width: 3.4rem;
    height: 2rem;
    align-self: center;
    justify-self: right;
    display: grid;
    grid-template-columns: repeat(2, 1.7rem);
  }
  .chevron{
    align-self: center;
    justify-self: center;
    cursor: pointer;
    path{
      fill: #F8FAFD;
    }
    &:hover{
      path{
        fill: $textColor;
      }
    }
    &.left{
      transform: rotate(180deg) translateX(-0.1rem);
      &.disabled{
        opacity: 0.5;
        cursor: not-allowed;
        &:hover{
          path{
            fill: #F8FAFD;
          }
        }
      }
    }
    &.right{
      transform: translateX(-0.1rem);
      &.disabled{
        opacity: 0.5;
        cursor: not-allowed;
        &:hover{
          path{
            fill: #F8FAFD;
          }
        }
      }
    }
  }



  .color-picker-box{
    padding: 2rem;
    //width: fit-content;

    p{
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 1rem;
      text-align: left;
    }

    .color-list{
      display: grid;
      grid-template-columns: repeat(3, 1.8rem);
      grid-template-rows: repeat(2, 1.8rem);
      grid-column-gap: 1.5rem;
      grid-row-gap: 1.5rem;
    }

    .color{
      width: 1.8rem;
      height: 1.8rem;
      display: inline-block;
      border-radius: 0.6rem;
      z-index: 1;
      cursor: pointer;
      &::after{
        @include pseudo;
        width: 2.2rem;
        height: 2.2rem;
        border: 0.2rem solid #3579DF;
        border-radius: 0.2rem;
        transform: translate(-0.4rem,-0.4rem);
        border-radius: 0.9rem;
        opacity: 0;
      }
      &::before{
        @include pseudo;
        width: 1.8rem;
        height: 1.8rem;
        background: rgba(0,0,0,0.15);
        z-index: 2;
        border-radius: 0.6rem;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      &.selected{
        &::after{
          opacity: 1;
        }
      }
      &:hover{
        &::before{
          opacity: 1;
        }
      }
    }
  }

</style>
