<template lang="pug">
  .salleedesign.wrapper
    .container.salleedesign
      .inside-section.narrow-section
        h1.introanim-1 Personal Work & Sallee Design
        p.large.introanim-2 This website is like my baby... I have put so many hours in it. It went through a lot of iterations through the years.
        .fadetogether.introanim-3
          p I built my first website with flash and Action Script in 2008. It was really bad. When I started transitioning as a designer I also learned HTML/CSS. I built my second website on Apache trying to fiddle with PHP. That was equaly bad. Then I discovered Wordpress and did a few personal websites with it.
          p At some point I realized that I didn’t need something as massive as Wordpress for a personal site. That’s when I started building more interesting, hand tailored personal sites. For a few years my personal site was built on static HTML/LESS/jQuery combo.


      .inside-section.narrow-section.introanim-4
        TitleWithLargeIcon(title="Sallee Design Today" imageName="code")
        p In 2019 I started eduating myself around javascript frameworks and ended up playing with <a href="https://vuejs.org/" target="_blank">Vue.js</a>
        p For a first project, I decided to redesign my static website with reusable Vue components. I was so impressed that I ended up designing and coding a brand new website.
        p The code base is so easy to move around and flexible that it got easy to update the site.

      .inside-section.introanim-5
        .photo1
          img(:src="getPublicImgUrl('photo-1')")

      .inside-section.narrow-section
        p My website has always been a training ground for me. Mostly for code. It is the main way I educate myself around good and modern HTML and CSS practices.

      .inside-section.narrow-section
        .icontitle
          IconPrototypes
          h3 Live elements made easy
        p The great thing about Vue is that nothing goes to waste as everything is componentized. If I build a small component for a release, I can always move it somewhere else with another release. All this without having to search through +1k lines of jQuery in a single file.
        p For example In the previous release, I had an avatar picker on my home screen as an intro. Just a way to showcase some interface design and code in one screen. You can now try here:

      .inside-section
        .avatar-container
          .tryme-container
            span.tryme Try me
          .avatar-section
            AvatarLarge(
              :imageSource="['avatar-1','avatar-2','avatar-3','avatar-4','avatar-5','avatar-6','avatar-7','avatar-8']"
              :gradientNumber="gradientSourceVar"
              :changeBg="true"
              :showSmallControls="true"
              @changeBgProp="changeBgProp")


      .inside-section.narrow-section
        .icontitle
          IconBranding
          h3 New Branding
        p I spent much more time than I usually do designing this new release. For my portfolios, I would generally design a few general models and concepts and would start coding early.
        p But tools like Figma are making it more and more easy to stay on the design tool, prototype concepts, and change components in one place with Design Systems and Styles. This makes for a tigher code integration.
        p Being an interface designer I don’t get to work too much with brand. It doesn’t mean I enjoy creating one. I always find that these new releases are a great opportunity to practice into it.
        p Here are a few brand elements I created and used through the site:

      .inside-section
        .photo2
          img(:src="getPublicImgUrl('photo-2')")

      .inside-section.narrow-section
        .icontitle
          IconToilet
          h3 Icons
        p Here is the 2 sets I created for this website.

      .inside-section
        .small-icons
          .icons-grid
            IconBranding
            IconCalendar
            IconCamera
            IconCertificate
            IconCoffee
            IconDribbble
            IconEmail
            IconHardDrive
            IconImages
            IconInstagram
            IconLinkedIn
            IconLocation
            IconMagazine
            IconMeeting
            IconMessages
            IconMouse
            IconPixels
            IconPrototypes
            IconShutter
            IconToilet
            IconUser
            IconWorld

      .inside-section
        .large-icons
          img(:src="getPublicImgUrl('iconsLarge')")

      .inside-section
        .large-icons.dark
          img(:src="getPublicImgUrl('iconsLargeDark')")

      .inside-section.narrow-section
        TitleWithLargeIcon(title="A retrospective" imageName="retrospective")
        p My first websites were entirely built to share free resources. I learnt photoshop by making free resources that I would share with the community. Free icons, wallpapers or coded journals and skins. This was the <a href="https://skins.webamp.org/" target="_blank">winamp skins</a> days. Everything was over made and very realistic. Here are a few images I found in the archives!


      .inside-section
        .photo3
          img(:src="getPublicImgUrl('photo-3')")

      .inside-section
        .photo4
          img(:src="getPublicImgUrl('photo-4')")

      .inside-section
        .photo5
          img(:src="getPublicImgUrl('photo-5')")

      .inside-section
        .glows
          .glow1
          .glow2

      .inside-section.narrow-section.getthatgapback
        .icontitle
          IconShutter
          h3 A mustache story in motion
        p Once I started having more experience and work to show, I transitioned to more classic portfolio websites. Nevertheless I was always trying to find ways to make my portfolio more engaging and less boring.
        p For a long time I used a mustache to engage users with my logo and to showcase some UI skills that didn’t have any purpose than just bringing some interaction and delight.
        p Here are a few evolutions of the <b>stache</b> and home animations through the years.

      .inside-section
        .gif-1
          img(:src="getPublicImgUrl('gif-1', 'gif')")

      .inside-section
        .gif-1
          img(:src="getPublicImgUrl('gif-2', 'gif')")

      .inside-section
        .gif-1
          img(:src="getPublicImgUrl('gif-3', 'gif')")

      .inside-section
        .gif-1
          img(:src="getPublicImgUrl('gif-4', 'gif')")

      .inside-section
        .gif-1
          img(:src="getPublicImgUrl('gif-5', 'gif')")

      .inside-section.narrow-section
        p It’s only in 2018 that I got a chance to work again on my site. I started playing with a cleaner and more streamlined layout. That would give me flexibility into the design of each page.
        p I played for a little bit with Jade before transitioning to Vue. I took away the mustache, it didn’t make sense anymore and would just use my name instead.
        p All the releases since then have been a step into the direction you see today, focusing on adding content. I don’t see every release as a complete re-design but rather as a step into the right direction every time...




</template>

<script>
// @ is an alias to /src
import TitleWithLargeIcon from '@/components/TitleWithLargeIcon.vue'
import AvatarLarge from '@/components/AvatarLarge.vue'
import IconBranding from '../assets/global/icons/Icon-Branding.svg';
import IconCalendar from '../assets/global/icons/Icon-Calendar.svg';
import IconCamera from '../assets/global/icons/Icon-Camera.svg';
import IconCertificate from '../assets/global/icons/Icon-Certificate.svg';
import IconCoffee from '../assets/global/icons/Icon-Coffee.svg';
import IconDribbble from '../assets/global/icons/Icon-Dribbble.svg';
import IconEmail from '../assets/global/icons/Icon-Email.svg';
import IconHardDrive from '../assets/global/icons/Icon-HardDrive.svg';
import IconImages from '../assets/global/icons/Icon-Images.svg';
import IconInstagram from '../assets/global/icons/Icon-Instagram.svg';
import IconLinkedIn from '../assets/global/icons/Icon-Linkedin.svg';
import IconLocation from '../assets/global/icons/Icon-Location.svg';
import IconMagazine from '../assets/global/icons/Icon-Magazine.svg';
import IconMeeting from '../assets/global/icons/Icon-Meeting.svg';
import IconMessages from '../assets/global/icons/Icon-Messages.svg';
import IconMouse from '../assets/global/icons/Icon-Mouse.svg';
import IconPixels from '../assets/global/icons/Icon-Pixels.svg';
import IconPrototypes from '../assets/global/icons/Icon-Prototypes.svg';
import IconShutter from '../assets/global/icons/Icon-Shutter.svg';
import IconToilet from '../assets/global/icons/Icon-Toilet.svg';
import IconUser from '../assets/global/icons/Icon-User.svg';
import IconWorld from '../assets/global/icons/Icon-World.svg';

export default {
  name: 'Salleedesign',

  components: {
    TitleWithLargeIcon,
    AvatarLarge,
    IconBranding,
    IconCalendar,
    IconCamera,
    IconCertificate,
    IconCoffee,
    IconDribbble,
    IconEmail,
    IconHardDrive,
    IconImages,
    IconInstagram,
    IconLinkedIn,
    IconLocation,
    IconMagazine,
    IconMeeting,
    IconMessages,
    IconMouse,
    IconPixels,
    IconPrototypes,
    IconShutter,
    IconToilet,
    IconUser,
    IconWorld
  },

  data() {
    return{
      gradientSourceVar: 5
    }
  },

  created(){
    document.body.style.setProperty('--bodyBg', '#F3F6FA');
  },

  beforeDestroy(){
    document.body.style.setProperty('--bodyBg', null);
  },


  mounted() {
    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 300,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 600,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-3'), 1000, 900,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1200,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-5'), 1000, 1500,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
  },

  methods: {

    changeBgProp(number){
      this.gradientSourceVar = number
    },

    getLargeIconsUrl(name) {
        return require(`@/assets/global/icons/large-icons/${name}.png`)
    },

    getLargeDarkIconsUrl(name) {
        return require(`@/assets/global/icons/large-icons/${name}-dark.png`)
    },

    getImgUrl(name) {
        return require(`@/assets/stealth/${name}`)
    },

    getPublicImgUrl(name, ext = 'png'){
      return this.urlMaker(name, 'sallee-design-vue-assets/work/salleedesign', ext)
    }
  }
}
</script>

<style scoped lang="scss">

  .inside-section{
    line-height: 0;
  }

  .photo1{
    width: 90%;
    max-width: 638px;
  }

  .avatar-container{
    width: 90%;
    max-width: 638px;
    background: linear-gradient(
      to left,
      #FF9A9E,
      50%,
      #FAD0C4
    );
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .tryme-container{
    padding: 3rem 2.5rem;

  }
  .tryme{
    //transform: translate3d(-250px, -100px, 0);
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    background: rgba(255,255,255,0.28);
    padding: 10px 15px 12px 15px;
    border-radius: 200px;
    width: auto;
  }

  .avatar-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 12rem;
    .avatar-large{

    }
  }

  .small-icons{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 638px;
    background: #fff;
    border-radius: 2rem;
    padding: 6rem 0;
    .icons-grid{
      display: grid;
      grid-template-columns: repeat(8, 1.6rem);
      grid-template-rows: repeat(3, 1.6rem);
      grid-column-gap: 3rem;
      grid-row-gap: 3rem;
      @include mq('600'){
        grid-template-columns: repeat(11, 1.6rem);
        grid-template-rows: repeat(2, 1.6rem);
        grid-column-gap: 3rem;
        grid-row-gap: 3rem;
      }
    }
  }

  .large-icons{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 638px;
    padding: 6.6rem 0 6.7rem 0;
    &.dark{
      background-color: #262D41;
      border-radius: 2rem;
    }
    img{
      max-width: 466px;
      width: 80%;
    }
  }

  .photo2{
    width: 90%;
    max-width: 638px;
  }

  .photo3, .photo4{
    width: 90%;
    max-width: 820px;
    z-index: 98;
    position: relative;
  }

  .photo5,{
    width: 90%;
    max-width: 526px;
    z-index: 99;
    position: relative;
  }

  .gif-1{
    width: 90%;
    max-width: 800px;
    border-radius: 1rem;
    overflow: hidden;
  }

  .glows{
    height: 0;
    overflow: visible;
    position: relative;
    z-index: 2;
    .glow1{
      width: 486px;
      height: 486px;
      //background: radial-gradient(50% 50% at 50% 50%, rgba(117, 180, 255, 0.5) 0%, rgba(117, 180, 255, 0) 100%);
      background-image:
        radial-gradient(
          circle,
          #75B4FF,
          #75B4FF
        );
      filter: blur(100px);
      transform: translate3d(0px, -386px, 0);
      display: block;
      opacity: 0.3;
    }
    .glow2{
        width: 486px;
        height: 486px;
        background-image:
          radial-gradient(
            circle,
            #FF75F9,
            #F3F6FA
          );
        filter: blur(100px);
        transform: translate3d(0px, -1200px, 0);
        display: block;
        opacity: 0.5;
    }
  }

  .getthatgapback{
    //margin-top: -6rem;
  }

  .iphone-screens{
    width: 90%;
    @include mq('600'){
      display: grid;
      grid-column-gap: 6rem;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      max-width: 1080px;
    }

    img{
      filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.11)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.079074)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0655718)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.055)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0444282)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.030926));
      margin-bottom: 6rem;
    }
  }


</style>
