<template lang="pug">
  .home.wrapper
    .container.home__container
      .inside-section.narrow-section
        h1.introanim-1 Hi there, I am Jeremy.
        p.large.introanim-2 I help companies define, design, build and measure simple human interfaces.
        .fadetogether.introanim-3
          p
            |Years of experience as a product designer, art director, and leading creative teams has made me&nbsp
            router-link(to="/about") who I am today
            |. With a work history rooted in design and engineering, I’m passionate about converting complex problems into simple and elegant solutions through a strong design process.
          p But I’m not <b>just</b> a designer that delivers visually stunning user-focused UIs. Having a technical background I fully participate in the flawless implementation of these deliverables in order to build and launch products that will create memorable experiences.

      .inside-section.introanim-4
        .graph-container
          HomeGraph1
          HomeGraph2

      .inside-section.narrow-section.introanim-5
        TitleWithLargeIcon(title="My Work" imageName="work")
        p
          |I have been fortunate enough to&nbsp
          router-link(to="/work") work
          |&nbspwith world-class organizations and teams on a wide variety of projects.
        p My relentless attention for <b>simplicity</b> and my love for <b>problem solving</b> has lead me to partner with ambitious visionaries, helping them get their ventures off the ground with design excellence.

      .inside-section
        .work-container(v-view="viewHandler")
          .left-section
            .work-title
              IconWork
              h3 +14 Years of Experience
            p An in depth exploration of my previous, full time work.
            .logo-list
              .logo-item
                .logo.stealth
                  StealthLogo
                p Stealth
              .logo-item
                .logo.nutanix
                  NutanixLogo
                p Nutanix
              .logo-item
                .logo.telly
                  TellyLogo
                p Telly
              .logo-item
                .logo.salleedesign
                  SalleeDesignLogo
                p Sallee Design
            router-link(to="/work") See my work
          .right-section
            img(:src="getImgUrlNewKillOldPlease('YouShouldCodeThis.png')")
          //this bugs mobile hard they are intense
          //.glow1
          //.glow2

      .inside-section.narrow-section
        TitleWithLargeIcon(title="Photography" imageName="photography")
        p
          |I originally built this website to showcase&nbsp
          router-link(to="/photography") my photography
          |. I wanted a very minimal experience where the user could solely focus on the content: photos and text. This whole website is designed and built towards that experience.
        p I also wanted to showcase my photography into small photo stories rather than a classic one-by-one photo gallery. Putting imagery and text together in order to bring a narrative of moods and atmospheres.

      .inside-section
        .photo-container
          .left-section(data-aos="fade-in")
            .photo-title.aos-sequence
              IconCamera
              h3 Photo Stories
            p.aos-sequence A collection of of hand crafted photo stories where imagery and text blend together immersing you into beautiful stories.
            .icon-list(data-aos="fade-in")
              .icon-item.aos-sequence
                IconMagazine
                p 9 Photo Stories
              .icon-item.aos-sequence
                IconImages
                p 1546 Total Pictures
              .icon-item.aos-sequence
                IconHardDrive
                p 510MB of Imagery
            router-link(to="/photography") Check Photo Stories
          .right-section
            img(:src="getImgUrlNewKillOldPlease('YouShouldCodeThisToo.png')")

      .inside-section.narrow-section
        TitleWithLargeIcon(title="Get in Touch" imageName="mail")
        p
          |I am currently open to design opportunities. If you want, you can view my <a href="https://www.linkedin.com/in/jeremy-sallée-7504355/" target="_black">LinkedIn</a> profile or download my&nbsp
          a(:href="`${publicPath}sallee-design-vue-assets/resume.zip`") resume
          |. I also showcase designs on <a href="http://dribbble.com/salleedesign" target="_blank">Dribbble</a>, casual pictures on <a target="_blank" href="http://instagram.com/jeremy_sallee">Instagram</a> and sometimes post on my <a target="_blank" href="https://twitter.com/salleedesign">Twitter</a> account.
        p If you have any questions, feedback, or just want to say hello, don't hesitate to <a href="mailto:jeremy.sallee@gmail.com">send me an email</a>.



</template>

<script>
// @ is an alias to /src
import IconWork from '../assets/global/icons/Icon-Mouse.svg'
import IconImages from '../assets/global/icons/Icon-Images.svg';
import IconMagazine from '../assets/global/icons/Icon-Magazine.svg';
import IconCamera from '../assets/global/icons/Icon-Camera.svg';
import IconHardDrive from '../assets/global/icons/Icon-HardDrive.svg';
import HomeGraph1 from '../assets/home/HomeGraph1.svg'
import HomeGraph2 from '../assets/home/HomeGraph2.svg'
import TitleWithLargeIcon from '@/components/TitleWithLargeIcon.vue'
import SalleeDesignLogo from '../assets/global/logos/logo-salleedesign.svg'
import TellyLogo from '../assets/global/logos/logo-telly.svg'
import NutanixLogo from '../assets/global/logos/logo-nutanix.svg'
import StealthLogo from '../assets/global/logos/logo-stealth.svg'

var once = 0

export default {
  name: 'home',
  components: {
    IconImages,
    IconMagazine,
    IconHardDrive,
    IconCamera,
    IconWork,
    HomeGraph1,
    HomeGraph2,
    TitleWithLargeIcon,
    SalleeDesignLogo,
    TellyLogo,
    NutanixLogo,
    StealthLogo
  },
  data() {
    return{
      publicPath: process.env.BASE_URL,
      fadeIn: false,
    }
  },
  mounted(){
    once = 0;
    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 300,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 600,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-3'), 1000, 900,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1200,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-5'), 1000, 1500,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
  },

  methods: {
    entering(){
      this.style.opacity = 0.2
      this.fadeIn = true
    },
    leaving(){
      //alert('leave')
      this.fadeIn = false
    },
    getImgUrl(name) {
        const photoname = `home-${name}`
        return require(`@/assets/home/${photoname}.png`)
    },

    getImgUrlNewKillOldPlease(name) {
        return require(`@/assets/home/${name}`)
    },

    viewHandler(e){
      if( e.target.element._prevClass == "work-container" ){
        if(e.percentCenter < 0.8){
          if( once == 0 ){
              this.globalTitleAnimation( e.target.element.querySelector('.left-section'), 1000, 0,'ease-out-cubic', -50, 0, 0, 0, 0, 1, 'x' )
              this.globalTitleAnimation( e.target.element.querySelector('.right-section'), 1000, 0,'ease-out-cubic', 50, 0, 0, 0, 0, 1, 'x' )
              once++
          }
        }
      }
      if( e.target.element._prevClass == "photo-container" ){
        if(e.percentCenter < 0.8){
          if( once == 1 ){
              this.globalTitleAnimation( e.target.element.querySelector('.left-section'), 1000, 0,'ease-out-cubic', -50, 0, 0, 0, 0, 1, 'x' )
              this.globalTitleAnimation( e.target.element.querySelector('.right-section'), 1000, 0,'ease-out-cubic', 50, 0, 0, 0, 0, 1, 'x' )
              once++
          }
        }
      }
        //this.globalTitleAnimation( e.target.element, 1000, 1200,'ease-out-cubic', 50, 0, 10, 0, 0, 1 )
    }
  }
}
</script>

<style scoped lang="scss">


  .home{
    &__container{

      .graph-container, .work-container, .photo-container{
        display: flex;
        flex-direction: column;
        align-items: stretch;

        background: linear-gradient(
          to left,
          #FFDDD1,
          50%,
          #FFF6EB
        );

        border-radius: 20px;
        column-gap: 0;
        row-gap: 5rem;
        width: 90%;
        max-width: 1003px;
        justify-content: center;
        align-items: center;
        padding: 10rem 0;
        opacity: 1;

        @include mq('900'){
          flex-direction: row;
          column-gap: 10rem;
          row-gap: 0;
        }

      }

      h1{
        text-align: left;
        width: 100%;
      }
      p{
        //opacity: 1;
        span{
          font-weight: 500;
        }
      }

      .graph-container{
        //opacity: 0;
        svg{
          transform: scale(0.8);
          @include mq('600'){
            transform: scale(1);
          }
        }
      }

      .work-container{

        overflow: hidden;

        background: linear-gradient(
          to left,
          #20293A,
          50%,
          #3F3F77
        );

        .glow1{
          z-index: 1;
          position: absolute;
          width: 667.32px;
          height: 748.64px;
          margin-right: 96rem;
          margin-top: -46rem;
          background: radial-gradient(158.99% 50% at 50% 50%, #8B78FF 0%, rgba(99, 73, 255, 0.37) 100%);
          mix-blend-mode: screen;
          filter: blur(569.754px);
          border-radius: 1139.51px;
          transform: matrix(-0.54, -0.7, -1.11, 0.37, 0, 0);

        }
        .glow2{
          z-index: 2;
          position: absolute;
          width: 667.32px;
          height: 748.64px;
          margin-left: 96rem;
          margin-top: -46rem;


          background: radial-gradient(158.99% 50% at 50% 50%, #FF7878 0%, rgba(242, 36, 148, 0.37) 100%);
          mix-blend-mode: screen;
          filter: blur(569.754px);
          border-radius: 1139.51px;
          transform: matrix(-0.54, -0.7, -1.11, 0.37, 0, 0);
        }

        .left-section{
          flex: 0 0 250px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          row-gap: 3rem;
          order: 1;
          opacity: 0;
          padding: 0 5rem;
          max-width: 442px;
          width: calc(100% - 10rem);

          @include mq('900'){
            padding: 0px;
            padding-left: 5rem;
            flex: 0 0 250px;
          }

          .work-title{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            column-gap: 1rem;
            h3{
              color: $white;
              font-size: 1.5rem;
              line-height: 2.2rem;
            }
            svg{
              .strong-highlight{
                fill: $white;
              }
              .soft-highlight{
                fill: transparent;
              }
              .white-highlight{
                fill: transparent;
              }
            }
          }
          p{
            color: #A395BF;
            font-size: 1.5rem;
            line-height: 2.2rem;
          }
          .logo-list{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0px;
            row-gap: 2rem;
            .logo-item{
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px;
              column-gap: 2rem;
              .logo{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0px;
                width: 32px;
                height: 32px;
                background: radial-gradient(99.91% 99.91% at 49.87% 0.09%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #F2A377;
                box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.3), inset 0px 1px 0px rgba(255, 255, 255, 0.3);
                border-radius: 8px;
                &.telly{
                  background: radial-gradient(99.91% 99.91% at 49.87% 0.09%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FF7575;
                }
                &.nutanix{
                  background: radial-gradient(99.91% 99.91% at 49.87% 0.09%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #747EA3;
                }
                &.salleedesign{
                  background: radial-gradient(99.91% 99.91% at 49.87% 0.09%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #635BFF;
                  svg{
                    transform: scale(0.85);
                  }
                }
              }
              p{
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 2.2rem;
                padding-bottom: 0.1rem;
              }
            }
          }

          a{
            background: #FF6F5B;
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px rgba(255, 255, 255, 0.21);
            border-radius: 8px;
            color: $white;
            font-size: 1.4rem;
            line-height: 2.2rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-bottom: 2px;
            width: 100%;
            height: 38px;
            transition: background 0.3s ease-out;
            &:hover{
              background: $primary-1;
            }
          }

        }
        .right-section{
          flex-grow: 1;
          max-width: 453px;
          padding: 0 5rem;
          z-index: 4;
          order: 0;
          opacity: 0;
          img{
            margin: 0;
          }
          @include mq('900'){
            padding: 0;
            padding-right: 5rem;
            order: 2;
          }

        }
      }

      .photo-container{
        background: #252C40;

        background: linear-gradient(
          to left,
          #5F1C48,
          50%,
          #252C40
        );

        overflow: hidden;
        max-width: 1042px;

        .left-section{
          flex: 0 0 250px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          row-gap: 3rem;
          order: 1;
          opacity: 0;
          padding: 0 5rem;
          max-width: 482px;

          @include mq('900'){
            padding: 0px;
            padding-left: 5rem;
          }

          .photo-title{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            column-gap: 1rem;
            h3{
              color: $white;
              font-size: 1.5rem;
              line-height: 2.2rem;
            }
            svg{
              .strong-highlight{
                fill: $white;
              }
              .soft-highlight{
                fill: transparent;
              }
              .white-highlight{
                fill: transparent;
              }
            }
          }
          p{
            color: #8C96A6;
            font-size: 1.5rem;
            line-height: 2.2rem;
          }
          .icon-list{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0px;
            row-gap: 1.5rem;
            .icon-item{
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px;
              column-gap: 1rem;

              svg{
                .strong-highlight{
                  fill: #8C96A6;
                }
                .soft-highlight{
                  fill: #252C40;
                }
                .white-highlight{
                  fill: #252C40;;
                }
              }

              p{
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 2.2rem;
                padding-bottom: 0.1rem;
              }
            }
          }

          a{
            background: $primary-1;
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px rgba(255, 255, 255, 0.21);
            border-radius: 8px;
            color: $white;
            font-size: 1.4rem;
            line-height: 2.2rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-bottom: 2px;
            width: 100%;
            height: 38px;
            transition: background 0.3s ease-out;
            &:hover{
              background: #FF6F5B;
            }
          }

        }
        .right-section{
          flex-grow: 1;
          max-width: 492px;
          padding: 0 5rem;
          z-index: 4;
          order: 0;
          opacity: 1;
          img{
            margin: 0;
            transform: translateY(-17.75px)
          }
          @include mq('900'){
            flex-grow: 1;
            order: 2;
            padding: 0;
            padding-right: 5rem;
          }

        }
      }

    }

    @keyframes slidein {
      0% {
        right: 230.18px;
        top: -720px;
      }

      50% {
        right: 100.18px;
        top: -620px;
      }

      100%{
        right: 230.18px;
        top: -720px;
      }
    }

  }

</style>

<style lang="scss">
  .navbar{
    opacity: 1;
  }
</style>
