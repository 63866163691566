<template lang="pug">
  .inside-section.narrow-section(data-aos="fade-in" data-aos-easing="ease-in" data-aos-duration="300")
    template(v-if="textSequence.length"  v-for="text in textSequence")
      .text.aos-sequence(v-if="text.type == 'paragraph'" :class="{ 'large': text.large }" v-html="getHtml(text.text)")
      h3.aos-sequence(v-if="text.type == 'h2'") {{ text.text }}
</template>

<script>
export default {
  name: 'PhotographyTextSequence',

  props: {
    text: Array
  },

  data() {
    return{
      textSequence: []
    }
  },

  mounted() {

    var newObj = {}

    this.$props.text.forEach((textbloc) => {
      if(textbloc.startsWith("p.large")){
        textbloc = textbloc.replace("p.large ", "")
        newObj = {}
        newObj["type"] = "paragraph"
        newObj["text"] = textbloc
        newObj["large"] = true
      }
      else if(textbloc.startsWith("h2")){
        textbloc = textbloc.replace("h2 ", "")
        newObj = {}
        newObj["type"] = "h2"
        newObj["text"] = textbloc
      }
      else{
        newObj = {}
        newObj["type"] = "paragraph"
        newObj["text"] = textbloc
        newObj["large"] = false
      }
      this.textSequence.push(newObj)
    })
  },

  methods:{
    getHtml(raw){
      return `<p>${raw}</p>`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .large p{
    font-size: 2.4rem;
    line-height: 3.4rem;
    color: var(--headersColor);
  }

</style>
