<template lang="pug">
  .nutanix-case-study
    .container.nutanix-case-study__container
      .inside-section.narrow-section
        h1.introanim-1 Nutanix Case Study
        p.large.introanim-2 Nutanix brings the IT technology of Google, Facebook and Amazon to the mainstream enterprises.
        .fadetogether.introanim-3
          p Created in 2009 there have been gaining an incredible amount of traction in the past few years
          p In early 2013 Nutanix was doing great. The new features of the product was bringing a lot of attention to the company. But their UI that manages and monitores this brand new, highly complex technology was still a skeuomorphic flex based app.
          p In an effort to bring a much better experience to the user the company was going through a complete revamping of that app. HTML5 based flat design was the goal. Lacking of a designer, the engineering team (7 engineers) hired a UX freelancer and started working on a bootstrap based app. This was the state of the project when I joined in.
          p This is how the flex based app looked like

      .inside-section.large-section
        AppImageFull.introanim-4(:imageData = "getImageData('photo-1')")

      .inside-section.narrow-section
        p(data-aos="fade-in") This is how the bootstrap app was looking the day I arrived

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-2')")
        AppImageFull(:imageData = "getImageData('photo-3')")
        AppImageFull(:imageData = "getImageData('photo-4')")

      .inside-section.narrow-section
        p(data-aos="fade-in")
          |You can download the whole archive of Nutanix design and wireframes before my arrival &nbsp
          a(:href="`${publicPath}sallee-design-vue-assets/nutanix-case-study/nutanix-before.zip`" target="_blank") here.
          |  It illustrates well how complex the product can be, but also the pain they were having to settle into one design solution.

      .inside-section.narrow-section(data-aos="fade-in")
        h3.aos-sequence April through August 2013
        h2.aos-sequence A complete re-design
        p.large.aos-sequence After spending a week playing with their live demo and wireframing on my sketchbook, I started to detect patterns within the UI.
        p.aos-sequence Submenus, tables, tabs, buttons and popups, there was a lot of reusable elements everywhere. The live app was also working good. It has some User Experience issues and some clear User Interface revamp to be made, but the engineering team had made a good job on de-grossing the complexity of such a product.
        p.aos-sequence The next step was pretty straight forward. Take the main pages of the UI and redesign them. Starting by the main style I was going to follow, the typography, the colors, and most importantly for such a data oriented product, the navigation.
        p.aos-sequence The style is very straight forward. It is such a complex and heavy user interface it needs a maximum of simplicity. For this the design needs to be entirelly stripped down to the minimal. A minimum amount of bg colors, separators and patterns. The focus is the data. Colors are also very sensible. The fact that it is an IT product, red, yellows and greens are reserved for alerts and statuses. I decided to go for monochrome and blueish colors for my main structure. And punch colors for highlighting.

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-5')")

      .inside-section.narrow-section
        p(data-aos="fade-in") In order to bring a slight touch of “fun” into the mix, I picked Proxima Nova Soft as the main typography. It is still serious enough but has those fancy looking round ends that gives it a special feeling. It looks good on small sizes too.

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-6')")

      .inside-section.narrow-section(data-aos="fade-in")
        p.aos-sequence Having colors and typography in the bag. I started designing. Followed a full month of designs, iterations, email and live feedback. I had also numerous “teaching” sessions with engineers in order to understand the UI. My position as always been the same in design. I need to understand what I am building. If I can’t, there won’t be a simple way I can translate that “tool/feature” into a real working interface. The end result will be too complex. During that month I started to set a very solid interface. With high consistency and reworked existing UX workflows.
        p.aos-sequence I started focusing on the tile pages first and then the table pages. Those two pages would be repeated through the app and have most of the ui elements. It made perfect sense to start by those. Not many wireframes were made (except part of the page to improve UX features) as most of the pages where already there in the live demos.
        p.aos-sequence Here are some early pages designs:

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-7')")

      .inside-section.narrow-section
        p(data-aos="fade-in") Here are the final designs:

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-8')")
        AppImageFull(:imageData = "getImageData('photo-9')")

      .inside-section.narrow-section
        p(data-aos="fade-in") When 90% of the design was ready we started focusing our efforts into the integration. Nearly the whole cascading style code was built in straight CSS. My first task was to educate the team around more powerful css coding and pixel perfect implementation. We picked LESS. I then spent the next two month helping the team on building a healthy LESS structure, with consistent variables for font families, weights and colors, logic functions for repeating buttons and UI elements. I also spent a good amount of time pairing with developers. Sitting many hours on their side trying to pass the knowledge on how to read a psd, extract the needed information from it and transform it into smart html/css.

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-10')")

      .inside-section.narrow-section
        p(data-aos="fade-in") During the integration process I also created an icon font. In my past experiences I have been involved into sprite heavy projects. With technology advancing and retina displays being more and more used, I have switched my integration workflow into creating light icon fonts. It brings a couple of disadvantages but the benefits are great. Very easy to translate into code, changing an icon size and color is a breeze. Not need of saving 40 times the same icon in different sizes and colors into a huge sprite. You can also easily transition smoothly from one color to another for hover states or any other interaction. Using images would require a hack.

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-11')")

      .inside-section.narrow-section(data-aos="fade-in")
        h2.aos-sequence The final stretch

        p.aos-sequence After month of implementation, integration and QA, v3.5 was getting close to completion. It was the final stretch moment. That weird moment before release, when all the developers are tackling bugs after bug revealed by QA. And then at any time one design quick fix is needed. Something is missing but has to be added to that or that feature. Sometimes, the designer in you wants to redesign the whole feature. But you can’t, you got to add it there, the most clever way you can. Sometimes the fix wouldn’t even need a design. Just a quick wireframe on a board. Sometimes it would require a balsamic wireframe or a full design. This is mostly the moment when your design starts deviating from the real app. It’s hard to keep up. In an effort to keep consistency everywhere I created a UI guide. That would help me and the developers to not deviate too much from the main style.

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-12')")

      .inside-section.narrow-section
        p(data-aos="fade-in") Finally version 3.5 featuring a brand new html5 management app was released. I wrapped the project participating with the marketing team in order to wrap up a video showcasing the benefits and new features of the UI. I was deeply involved into the script writing, storyboarding and then recorded the video footage and edited the whole in After Effects.

      .inside-section.large-section
        .embed-container
          iframe(src="https://player.vimeo.com/video/72747786?h=dfec8f835f" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture allowfullscreen")


      .inside-section.narrow-section
        p(data-aos="fade-in") Here is a couple of tweets from that release:

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-13')")

      .inside-section.narrow-section
        p(data-aos="fade-in")
          |You can find a complete archive for 3.5 release &nbsp
          a(:href="`${publicPath}nutanix-case-study/nutanix-3.5.zip`" target="_blank") here.



      .inside-section.narrow-section(data-aos="fade-in")
        h3.aos-sequence September 2013 through Today
        h2.aos-sequence v4.0 Ahead
        p.large.aos-sequence Before even the version 3.5 was out I was already working on new features that we were preparing for 4.0.
        p.aos-sequence There was 2 new main features. Multicluster UI called prism central. Version 3.5 let’s you manage a single cluster. v4.0 has to let you manage multiple cluster. All of them having their own internal menu. Scalable from 2 clusters to hundreds. The second feature was cluster health. Hundreds of tests are ran into thousands of different entities within the systems. An interface is needed to navigate through that complex architecture and target the right failing tests in order to find the critical issues within your system.

        h3.aos-sequence Multicluster
        p.aos-sequence I started by multi cluster has this would need to rework the navigation system. After a couple of tries I settled into a sliding side menu. This menu would be composed of a list of clusters allowing the user to switch from cluster to cluster anytime within the app. Also the whole alert system was to be thought again. The old one had some confusion going on between alerts, progresses and the amount of alerts. The new design had to solve that too.
        p.aos-sequence Again, as this is not a huge page but more a vital iteration into the navigation the work was made straight up in Photoshop with meeting with engineers and support engineers (power users) in order to understand how important and useful is to have persistent progresses and alerts and how to present them.
        p.aos-sequence Here is the first iteration on the header:

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-14')")

      .inside-section.narrow-section
        p(data-aos="fade-in") Here is the final design for the new navigation allowing you to pass from cluster to cluster:

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-15')")

      .inside-section.narrow-section(data-aos="fade-in")
        h3.aos-sequence Cluster Health

        p.aos-sequence The other main feature I had to solve too was Cluster Health. When I started working on this one I had no idea that this new feature would take more than 4 month to build from scratch. It all started by a python code page that was sent to me. Backend engineers had come with this new feature where hundreds of tests are run every minute in average to all the entities on the system. A cluster has a very complex parenting architecture where elements are inter-dependable. Especially when it would come to failed tests. The whole goal of this tool is to be able to navigate through a huge and complex library of elements and target the root entities causing the problems. The interdependency of the entities can create chain effects, fooling the user on looking the problem on the wrong entity. Confident that I would find a solution in a week I started designing. After 2-3 weeks I had created numerous amount of designs that were instantly killed. The ideas were still coming from all boards, front end, back end, PMs… Here is a couple of photoshop and wireframes explorations that were made during that time.

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-16')")
        AppImageFull(:imageData = "getImageData('photo-17')")

      .inside-section.narrow-section
        p(data-aos="fade-in") After some trial and error and many meetings, a solution started to rise. We will be grouping entities by other parameters like Memory or Parent. This will help the user to have a quick overview of the system in a glimpse and already have an idea on what could be the issue. For example grouping virtual machines by memory used could reveal that all the VMs are in a high memory state require the user to allow more space to them or reduce the amount of them. This was the real start of cluster health. I was finally starting to get a clear picture in my head. The rest was only doing all the pages, iterate daily by meeting with the engineers and PMs in order to share the evolution with them. This was the state of the design after 2 month

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-18')")

      .inside-section.narrow-section(data-aos="fade-in")
        p.aos-sequence We then started the process of integration. This took a pretty long time and my involvement was at first to have a very quick view on the overall progress in the integration. The closest we got to a complete point the more I started to get involved in the final integration touches. Pixel perfect css, transitions and animations and making sure that the UX was right. This required a numerous of small tweaks live in the App that I tried to reflect in the photoshop file in order to keep everything at synch.

        p.aos-sequence Around january we got to a point the app was good enough to start user testings. We conduced a series of user tests. This brought to light many problems into the UI. Not only visual problems, but also navigation and user experience ones. Learning from that user sessions I tried to take every user feedback and make a full round of iterations to the pages. This solved some issues but not everything. The app being so complex, a full on boarding starting to be an evident solution. We started with the css team to explore an in house solution for a smart on-boarding composed of information bubbles and live mouse interaction. Making the whole experience way more understandable (teach by the example, there is numerous examples out there doing so, like project management apps that create a fake project for you on on-boarding in order to guide you through the app…). Once we were able to technically go for something nice, I came up with an explanatory design for the final integration. Once this coded we refined the copy with PMs.

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-19')")

      .inside-section.narrow-section
        p(data-aos="fade-in") Here is a quick demo video of the working plugin

      .inside-section.large-section
        video(width="900" height="506" controls)
          source(:src="`${publicPath}sallee-design-vue-assets/nutanix-case-study/onboarding.mp4`" type="video/mp4")

      .inside-section.narrow-section
        p(data-aos="fade-in") While working on Multicluster and Cluster Health I quickly realized I needed to reiterate in the overall design. The 2 new features had brought new elements I needed to be consistent with in the rest of the app. But also I realized I needed to simplify even more the layout and design. I reiterated into the design and we tackled it together with a new css specialist that we got onboard since 4.0

      .inside-section.large-section
        AppImageFull(:imageData = "getImageData('photo-20')")

      .inside-section.narrow-section
        p(data-aos="fade-in")
          |You can find a complete archive for 4.0 release &nbsp
          a(:href="`${publicPath}nutanix-case-study/nutanix-4.0.zip`" target="_blank") here.


      .inside-section.narrow-section(data-aos="fade-in")
        h3.aos-sequence September 2013 through Today
        h2.aos-sequence Teach Hire Manage

        p.large.aos-sequence Since the release of 3.5 last september I have been continuously involved into more management and recruitment.
        p.aos-sequence In order to leverage the amount of code and design we were doing we hired a css specialist on the front end engineer team. After ramping up for about 2 month I have been able to communicate all the “visual related” integrations need and not code anymore. This required some teaching and a good communication system between the two of us, that is, so far working perfectly.
        p.aos-sequence I have also been deeply involved into hiring designer to create a team. We hired one designer in January 2014 that I started managing remotely (he was based in Shanghai at the time) while we were solving legal issues. We also already signed a second designer that will be finishing his design studies late July and join us.
        p.aos-sequence We are trying to find another one. In an effort to attract talent to our team I took the liberty to design and code a <a href="http://salleedesign.com/nutanix-is-hiring" target="_blank">live position page.</a>





</template>

<script>
// @ is an alias to /src
import CaseStudyMetadata from '@/data/nutanix-case-study.json'
import AppImage from '@/components/AppImage.vue'
import AppImageUnderBox from '@/components/AppImageUnderBox.vue'
import AppImageFull from '@/components/AppImageFull.vue'

export default {
  name: 'NutanixCaseStudy',

  components: {
    AppImage,
    AppImageUnderBox,
    AppImageFull,
  },

  data() {
    return{
      postUrl: 'sallee-design-vue-assets/work/nutanix-case-study',
      caseStudyTitle: 'Nutanix Case Study',
      caseStudySubtitle: 'My work at Nutanix from Apr 2013 to Nov 2013',
      postMeta: CaseStudyMetadata,
      publicPath: process.env.BASE_URL
    }
  },

  mounted() {
    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 300,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 800,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-3'), 1000, 1300,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1800,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
  },

  created(){
    document.body.style.setProperty('--bodyBg', '#EFF2F6');
  },

  beforeDestroy(){
    document.body.style.setProperty('--bodyBg', null);
  },

  methods: {
    getImageData(name){
      let imageDataObj = {}
      imageDataObj.width = this.getPictureWidth(name)
      imageDataObj.height = this.getPictureHeight(name)
      imageDataObj.url = this.getPictureUrl(name, this.postUrl)
      imageDataObj.single = true
      return imageDataObj
    },

    getPictureWidth(i) {
      return this.postMeta[`${i}`]["width"]
    },

    getPictureHeight(i) {
      return this.postMeta[`${i}`]["height"]
    },

    getPictureUrl(i, url) {
      return this.urlMaker(i, url)
    }
  }
}
</script>

<style scoped lang="scss">
  .nutanix-case-study{
    .expandable-image{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

  }
</style>
