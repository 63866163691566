import { render, staticRenderFns } from "./TitleWithLargeIcon.vue?vue&type=template&id=33d89e18&scoped=true&lang=pug&"
import script from "./TitleWithLargeIcon.vue?vue&type=script&lang=js&"
export * from "./TitleWithLargeIcon.vue?vue&type=script&lang=js&"
import style0 from "./TitleWithLargeIcon.vue?vue&type=style&index=0&id=33d89e18&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d89e18",
  null
  
)

export default component.exports