<template lang='pug'>
  .title-with-large-icon
    .icon-wrapper
      img(
        :src="getImgUrl()")
    h2 {{ title }}
</template>

<script>

export default {
  name: 'ScrollDownText',

  props: {
    imageName: String,
    title: String,
  },

  data(){
    return{
    }
  },

  mounted() {
  },

  methods: {
      getImgUrl() {
          return require(`@/assets/global/icons/large-icons/${this.imageName}.png`)
      }
    }
}

</script>

<style scoped lang='scss'>
  .title-with-large-icon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 3rem;
  }

  .icon-wrapper{
    width: 6.8rem;
    height: 6.8rem;


    border: 1px solid rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.08));

    border-radius: 200px;
    overflow: hidden;
    &::after{
      box-shadow: inset 0px 1px 0px #FFFFFF;
      border-radius: 200px;
      content: '';
      display: block;
      height: 6.8rem;
      width: 6.8rem;
      position: absolute;
      top: 0;
    }
  }

  img{
    margin: 0;

  }
</style>
