import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Photography from '../views/Photography.vue'
import PhotographyDetailPage from '../views/PhotographyDetailPage.vue'
import NutanixCaseStudy from '../views/NutanixCaseStudy.vue'
import NutanixWork from '../views/NutanixWork.vue'
import Work from '../views/Work.vue'
import About from '../views/About.vue'
import Stealth from '../views/Stealth.vue'
import Telly from '../views/Telly.vue'
import Salleedesign from '../views/Salleedesign.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/work/nutanix-case-study',
    name: 'NutanixCaseStudy',
    component: NutanixCaseStudy
  },
  {
    path: '/work/nutanix-work',
    name: 'NutanixWork',
    component: NutanixWork
  },
  {
    path: '/photography',
    name: 'photography',
    component: Photography
  },
  {
    path: '/work',
    name: 'Work',
    component: Work
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/work/stealth',
    name: 'Stealth',
    component: Stealth
  },
  {
    path: '/work/telly',
    name: 'Telly',
    component: Telly
  },
  {
    path: '/work/salleedesign',
    name: 'Salleedesign',
    component: Salleedesign
  },
  {
    //we use the title (with dash) in the url and we set props to true to pass the post object
    path: '/photography/:routerUrl',
    name: 'PhotographyDetailPage',
    component: PhotographyDetailPage,
    props: true
  }
]

/*
This config will use the pound key with the url (ie salleedesign.com/#about)
const router = new VueRouter({
  routes
})
*/

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
