<template lang="pug">
  .expandable-image(
    :class = "{ 'expanded': imageExpand, 'expandable': expandable }"
    @click = "imageExpand = true")
    img.AppImage.lozad(
      :data-src="lazySrc"
      :data-srcset="lazySrcset"
      :style="style")
</template>

<script>
import lozad from 'lozad';

export default {
  name: 'AppImage',
  props: {
    height: {
      type: Number,
      default: null,
    },
    lazySrc: {
      type: String,
      default: null,
    },
    lazySrcset: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      imageExpand: false
    };
  },
  computed: {
    aspectRatio() {
      // Calculate the aspect ratio of the image
      // if the width and the height are given.
      if (!this.width || !this.height) return null;

      return (this.height / this.width) * 100;
    },
    style() {
      // The background color is used as a
      // placeholder while loading the image.
      // You can use the dominant color of the
      // image to improve perceived performance.
      // See: https://manu.ninja/dominant-colors-for-lazy-loading-images/
      const style = { };

      if (this.width) style.width = `${this.width}px`;

      // If the image is still loading and an
      // aspect ratio could be calculated, we
      // apply the calculated aspect ratio by
      // using padding top.
      const applyAspectRatio = this.loading && this.aspectRatio;
      if (applyAspectRatio) {
        // Prevent flash of unstyled image
        // after the image is loaded.
        style.height = 0;
        // Scale the image container according
        // to the aspect ratio.
        let widthPercentage = this.width / 1320
        if(widthPercentage > 1) widthPercentage = 1;
        style.paddingTop = `${this.aspectRatio*widthPercentage}%`;
      }
      else{
        this.$emit('loadedImageEvent');
      }

      return style;
    },
  },
  mounted() {
    // As soon as the <img> element triggers
    // the `load` event, the loading state is
    // set to `false`, which removes the apsect
    // ratio we've applied earlier.
    const setLoadingState = () => {
      this.loading = false;
    };
    this.$el.querySelector("img").addEventListener('load', setLoadingState);
    // We remove the event listener as soon as
    // the component is destroyed to prevent
    // potential memory leaks.
    this.$once('hook:destroyed', () => {
      this.$el.querySelector("img").removeEventListener('load', setLoadingState);
    });

    // We initialize Lozad.js on the root
    // element of our component.
    const observer = lozad(this.$el.querySelector("img"), {
    loaded: function(el) {
        el.onload = function() {
          el.classList.add('lozad--loaded');
        };
      },
      rootMargin: '0% 0% 100%',
    });
    observer.observe();
  },

  watch: {
    imageExpand (imageExpand) {
      if(this.expandable){
        this.$nextTick(() => {
          // Run this if when we're expanding the image
          if (imageExpand) {
            // Clone the entire expandable-image element
            this.cloned = this.$el.cloneNode(true)
            // Store a reference for the close button
            this.clonedImage = this.cloned.querySelector('.AppImage')
            // Call closeImage when the close button is clicked
            this.clonedImage.addEventListener('click', this.closeImage)
            // Add the cloned element into <body>
            document.body.appendChild(this.cloned)
            this.clonedImage.addEventListener('load', this.cloneLoaded)
            this.clonedImage.style.opacity = 0
            // Prevent the page from scrolling
            document.body.style.overflow = 'hidden'
            setTimeout(() => {
              // Show the cloned element
              this.cloned.style.opacity = 1
            }, 0)
          } else {
            // This section will run when the image is closing
            // Hide the expanded image
            this.cloned.style.opacity = 0
            setTimeout(() => {
              // Then, remove the click event listener from the close button
              this.clonedImage.removeEventListener('click', this.closeImage)
              // Remove the cloned element and the references
              this.cloned.remove()
              this.cloned = null
              this.clonedImage = null
              // Re-enable the scrolling
              document.body.style.overflow = 'auto'
            }, 250)
          }
        })
      }
    }
  },

  methods:{
    closeImage(){
      this.imageExpand = false
    },
    cloneLoaded(evt){
      evt.currentTarget.style.opacity = 1
    }
  }

};
</script>

<style scoped lang="scss">
// Responsive image styles.
.AppImage {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
  //transition: 0.5s opacity ease-out;
  opacity: 0;
  margin: 0;
  &.lozad--loaded{
    opacity: 1;
  }
}

.expandable-image {
  position: relative;
  transition: 0.25s opacity;
  z-index: 1;
  &.expandable{
      cursor: pointer;
  }
}
body > .expandable-image.expanded {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  opacity: 0;
  padding-bottom: 0 !important;
  cursor: pointer;
}
body > .expandable-image.expanded > img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.expandable-image img {
  width: 100%;
}

</style>
