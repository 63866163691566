<template lang="pug">
  .img-container
    img.preloads(
      v-if="startLoad"
      :src="backgroundImage"
      @load="onLoadHandler(backgroundImage)")
    transition(name="imagefade")
      .top-intro(
        v-if="imageLoaded"
        :style="backgroundImageBG")
</template>

<script>

export default {
  name: 'HeroBgImage',
  props: {
    startLoad: Boolean,
    backgroundImage: String,
  },

  data() {
    return {
      backgroundImageBG: '',
      imageLoaded: false
    }
  },

  mounted(){
  },

  methods: {
    onLoadHandler(imageSource){
      const style = { backgroundImage: `url(${imageSource})` }
      this.backgroundImageBG = style
      this.imageLoaded = true
      this.$emit('imageLoaded')
    }
  },

}
</script>


<style scoped lang="scss">

  .imagefade-enter-active,
  .imagefade-leave-active {
      transition: opacity 1s ease;
   }

  .imagefade-enter,
  .imagefade-leave-active {
      opacity: 0;
  }

  .preloads{
    display: none;
  }

  .top-intro{
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    position: absolute;
  }

</style>
