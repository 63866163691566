<template lang="pug">
  .nutanix-work
    .container.nutanix-work
      .inside-section.narrow-section
        h1.introanim-1 Enterprise Cloud Design at Nutanix
        p.large.introanim-2 I arrived at Nutanix in 2013 as the first product designer. Nutanix was a startup with 200 employees and 2 key products.
        .fadetogether.introanim-3
          p In June 2020 when I left the company, the design team had grown to 50 people in 3 geographical locations, while Nutanix had grown to a IPO’d company with 7000 employees.
          p The design foundations I set up in 2013 for myself and the company were fundamental to the growth of the team and the company and are still used today by the entire design team. During my stay at Nutanix I also contributed to the delivery of top quality designs, like a design system, but also fully participated and led the growth of the team, design processes and tools.
          p Please find the following key contributions I’ve made at Nutanix. I am happy to share more in-depth information about individual projects over the phone or through a presentation.


      .inside-section.narrow-section.introanim-4

        h3 2013-2015
        h2 Product Design Foundation & Redesign
        p <strong>Link: </strong>
          router-link(to="/work/nutanix-case-study") salleedesign.com/work/nutanix-foundation
        p <strong>Role: </strong> Design, HTML/CSS/JS
        p <strong>Description: </strong> My first task when I joined Nutanix, as the sole designer, was to lay out solid foundations for the years to come, and in the process completely redesign their products. I created the design foundations, philosophy and rules that are still in use 7 years later: typography, iconography, colors, design direction and product design philosophy. I also worked tightly with the front end engineers in order to improve our pixel perfect HTML/CSS implementation and adopted a preprocessing CSS language, LESS.

      .inside-section.large-section
        img(:src="getPublicImgUrl('photo-1')")
        img.image-shadow(:src="getPublicImgUrl('photo-2','jpg')")
        img.image-shadow(:src="getPublicImgUrl('photo-3','jpg')")
        img.image-shadow(:src="getPublicImgUrl('photo-4','jpg')")

      .inside-section.narrow-section
        h3 2013-2020
        h2 Nutanix Design System
        p <strong>Link: </strong><a href="http://salleedesign.com/nutanix-design/" target="_blank">nutanix.design/system/</a>
        p <strong>Role: </strong> Design, Design Lead, Design Manager, HTML/CSS/JS
        p <strong>Description: </strong> In the span of 7 years I created and participated in the creation of 3 Design Systems at Nutanix. The first two were design guides rather than full design systems. In 2016 our team of 3 designers created the first official Nutanix Design System. This resulted in the creation of the <a href="http://nutanix.design" target="_blank">nutanix.design</a> consumer website and the adoption of a consistent look and feel by all the designers within our cross geographical team. We then continued iterations on the System based on designer feedback and needs.
        p We also, in parallel, worked tightly with the engineering team in order to create a full React.js library from the Design System components.

      .inside-section.large-section
        img(:src="getPublicImgUrl('photo-5')")
        img.image-shadow(:src="getPublicImgUrl('photo-6','jpg')")
        img(:src="getPublicImgUrl('photo-7','jpg')")
        img.image-shadow(:src="getPublicImgUrl('photo-8','jpg')")

      .inside-section.narrow-section
        h3 2018-2020
        h2 Nutanix Illustration Team & Asset Library
        p <strong>Link: </strong><a href="http://nutanix.design/illustrations/" target="_blank">nutanix.design/illustrations/</a>
        p <strong>Role: </strong> UI Design, Design Lead, Design Manager, Artistic Director, HTML/CSS/JS
        p <strong>Description: </strong> For my first 5 years at Nutanix we never had any illustrators on the team. We’ve had a few individual contributors with some illustration skills, but there was never a unified effort to create illustrations for our Nutanix products. In a year and a half, I pitched, hired and managed an illustration team. This resulted in the creation of a 4 person team, an illustration system and a tighter collaboration with the marketing team. We also released in 2020 our Illustration Asset Library, which allows any user to browse and download any illustration we have built in both our product and marketing palettes.

      .inside-section.large-section
        img(:src="getPublicImgUrl('photo-9')")
        img(:src="getPublicImgUrl('photo-10')")

      .inside-section.narrow-section
        h3 2013-2020
        h2 Team Building, Leadership & Management
        p <strong>Description: </strong> I was instrumental in building the Nutanix design team and its processes. I hired many designers, worked with agencies, and managed many freelancers. I also fully participated in tweaking and improving our many design processes, like a UX gate or a Fit and Finish gate.


      .inside-section.narrow-section
        h3 September 2016
        h2 Nutanix Mobile Design
        p <strong>Role: </strong> Project Lead
        p <strong>Description: </strong> In September 2016 we wanted to prototype our first mobile application. Lacking in-house designers we decided to hire a design agency. I acted as the single point of contact with the <a href="http://clay.global" target="_blank">Clay design agency</a>, reaching out to them and acting as the Project Manager and Creative Director during the whole project. I defined the requirements of the app and approved the screen designs.

      .inside-section.large-section
        img(:src="getPublicImgUrl('photo-11')")
        img(:src="getPublicImgUrl('photo-12')")
        img(:src="getPublicImgUrl('photo-13')")

      .inside-section.narrow-section
        h3 2014-2015
        h2 Nutanix.com Redesign
        p <strong>Link: </strong><a href="http://nutanix.com" target="_blank">nutanix.com</a>
        p <strong>Role: </strong> Early design concepts, design lead, HTML/CSS/JS
        p <strong>Description: </strong> In early 2014 I helped Nutanix to launch a new marketing website. The foundations used at the time are still present on the website today. Nutanix.com being a CMS (Wordpress), I designed the main concepts and pages of the site: page layout options, responsiveness, typography, colors and also the main product pages, blog pages, blog posts and company pages. In order to help me with the amount of work I led 2 other designers. Once we were happy with the overall concepts, I then proceeded to build a fully workable prototype with a front end freelancer. This prototype was then handed to a design agency in order to integrate the code in Wordpress.

      .inside-section.large-section
        img.image-shadow(:src="getPublicImgUrl('photo-14','jpg')")

      .inside-section.narrow-section
        h3 2013-2020
        h2 Nutanix Extra Projects
        p <strong>Links: </strong><a href="https://nutanixbible.com/" target="_blank">nutanixbible.com</a>, <a href="http://salleedesign.com/stuff/experiments/nutanix-design/" target="_blank">nutanix.design.v2</a>
        p <strong>Role: </strong> Design, Design Lead, Design Manager, HTML/CSS/JS
        p <strong>Description: </strong> Here are a few extra projects worth showing. In order: the Nutanix Bible, a new version of nutanix.design (never released) and Nutanix Soft font creation.

      .inside-section.large-section
        img.image-shadow(:src="getPublicImgUrl('photo-15','jpg')")
        img.image-shadow(:src="getPublicImgUrl('photo-16','jpg')")
        img.image-shadow(:src="getPublicImgUrl('photo-17','jpg')")


</template>

<script>
// @ is an alias to /src
import CaseStudyMetadata from '@/data/nutanix-work.json'
import AppImageFull from '@/components/AppImageFull.vue'

export default {
  name: 'NutanixWork',

  components: {
    AppImageFull
  },

  data() {
    return{
      postUrl: 'sallee-design-vue-assets/work/nutanix-work',
      postMeta: CaseStudyMetadata,
      publicPath: process.env.BASE_URL
    }
  },

  created(){
    document.body.style.setProperty('--bodyBg', '#EFF2F6');
  },

  beforeDestroy(){
    document.body.style.setProperty('--bodyBg', null);
  },


  mounted() {
    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 300,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 600,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-3'), 1000, 900,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1200,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
  },

  methods: {
    getImageData(name, ext = 'jpg'){
      let imageDataObj = {}
      imageDataObj.width = this.getPictureWidth(name)
      imageDataObj.height = this.getPictureHeight(name)
      imageDataObj.url = this.getPictureUrl(name, this.postUrl, ext)
      imageDataObj.single = true
      return imageDataObj
    },

    getPictureWidth(i) {
      return this.postMeta[`${i}`]["width"]
    },

    getPictureHeight(i) {
      return this.postMeta[`${i}`]["height"]
    },

    getPictureUrl(i, url, ext) {
      return this.urlMaker(i, url, ext)
    },

    getPublicImgUrl(name, ext = 'png'){
      return this.urlMaker(name, this.postUrl, ext)
    }
  }
}
</script>

<style scoped lang="scss">
  .nutanix-work{
    .container{

      //background-color: $dark-5;

      .full-width-pic{
        &.image-shadow{
          border-radius: 5px;
          overflow: hidden;
          box-shadow: 0 30px 60px -10px rgba(50, 50, 93, 0.10), 0 25px 35px -20px rgba(0,0,0,0.05);
          //box-shadow:rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        }
      }

      img.image-shadow{
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 30px 60px -10px rgba(50, 50, 93, 0.10), 0 25px 35px -20px rgba(0,0,0,0.05);
        //box-shadow:rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }

      .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        margin-bottom: 6rem;
        margin-top: 6rem;
        iframe, object, embed{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      video{
        margin-top: 3rem;
        margin-bottom: 6rem;
      }
    }

    &__figure{
      margin-top: 9rem;
      margin-bottom: 9rem;
      svg{
        height: 32px;
        width: 64px;
        @include push--auto;
        path{
          fill: #2A2A2A;
        }
      }
    }

  }
</style>
