<template lang="pug">
  .stealth
    .container.nutanix-work
      .inside-section.narrow-section
        h1.introanim-1 Stealth Work
        p.large.introanim-2 In September 2020 I joined a Startup in stealth mode as the founding designer.
        p.introanim-3 Solving complex problems from the ground up with just the founder’s vision is not an easy task. I have been using my full designer arsenal for this job.


      .inside-section.narrow-section.introanim-4
        TitleWithLargeIcon(title="Fast Iterations" imageName="interactions-dark")
        p In such an early stage company flexibility into the process is definitely key. New design tasks can be given with a well-structured PRDs or through a few comments and doodles in a zoom conversation.
        p Rapid <b>Prototyping</b>, an early <b>design system</b> and <b>figma</b> (Can I buy a beer to the inventor of auto layout?) have been extremely instrumental in this fast paced startup environment.

      .inside-section.graphics.introanim-5
        img(:src="getImgUrl('YouShouldCodeThis.png')")

      .inside-section.legend(data-aos="fade-in")
        p.legend Designs for illustration purposes only, not part of my actual work

      .inside-section.narrow-section(data-aos="fade-in")
        p.aos-sequence Quick iterations while keeping a consistent identity through a product is vital in such an early stage. Having the ability to change layouts and designs in a timely manner while communicating accurately around these has been a trademark of this experience.
        p.aos-sequence
          |Our product being into pre-release, I cannot share with you the name of the company or what I have been working on. In the meantime you can still check my work at&nbsp
          |In 2020, I joined a&nbsp
          router-link(to="/work/nutanix-work") Nutanix
          |,&nbsp
          router-link(to="/work/telly") Telly
          |&nbsp and my &nbsp
          router-link(to="/work/salleedesign") personal projects
          |.





</template>

<script>
// @ is an alias to /src
import TitleWithLargeIcon from '@/components/TitleWithLargeIcon.vue'

export default {
  name: 'Stealth',

  components: {
    TitleWithLargeIcon
  },

  data() {
    return{
    }
  },

  created(){
    document.body.style.setProperty('--bodyBg', '#161B28');
    document.body.style.setProperty('--paragraphColor', '#7A8598');
    document.body.style.setProperty('--headersColor', '#FFFFFF');
    document.body.style.setProperty('--legendColor', '#313E53');
    document.body.style.setProperty('--linkColor', 'hsla(var(--blue-1-lighter-hsl), 1)');
    document.body.style.setProperty('--linkColorHover', 'rgba(255,255,255,1)');

    document.body.style.setProperty('--logoBgColor', '#20293A');
    document.body.style.setProperty('--logoBoxShadow', '0px 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 3px rgba(0, 0, 0, 0.13), inset 0px 1px 0px rgba(255, 255, 255, 0.08)');

    document.body.style.setProperty('--footerCopyrightColor', 'rgba(255,255,255,0.2)');
    document.body.style.setProperty('--footerBoxShadow', '0 -1px 0 rgba(255,255,255,0.05)');
    document.body.style.setProperty('--footerIconsColor2', 'transparent');
    document.body.style.setProperty('--footerIconsColor3', 'hsla(218,13%,54%, 0.3)');
    document.body.style.setProperty('--footerIconsColor1Hover', 'var(--linkColor)');
    document.body.style.setProperty('--footerIconsColor2Hover', 'transparent');
    document.body.style.setProperty('--footerIconsColor3Hover', 'hsla(var(--blue-1-lighter-hsl), 0.5)');
  },

  beforeDestroy(){
    document.body.style.setProperty('--bodyBg', null);
    document.body.style.setProperty('--paragraphColor', null);
    document.body.style.setProperty('--headersColor', null);
    document.body.style.setProperty('--legendColor', null);
    document.body.style.setProperty('--linkColor', null);
    document.body.style.setProperty('--linkColorHover', null);

    document.body.style.setProperty('--logoBgColor', null);
    document.body.style.setProperty('--logoBoxShadow', null);

    document.body.style.setProperty('--footerCopyrightColor', null);
    document.body.style.setProperty('--footerBoxShadow', null);
    document.body.style.setProperty('--footerIconsColor2', null);
    document.body.style.setProperty('--footerIconsColor3', null);
    document.body.style.setProperty('--footerIconsColor1Hover', null);
    document.body.style.setProperty('--footerIconsColor2Hover', null);
    document.body.style.setProperty('--footerIconsColor3Hover', null);
  },


  mounted() {
    this.globalTitleAnimation( document.querySelector('.introanim-1'), 1000, 300,'ease-out-cubic', 30, 0, 0, 0, 0, 1 )
    this.globalTitleAnimation( document.querySelector('.introanim-2'), 1000, 600,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-3'), 1000, 900,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-4'), 1000, 1200,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
    this.globalTitleAnimation( document.querySelector('.introanim-5'), 1000, 1500,'ease-out-cubic', 30, 0, 0, 0, 0, 1)
  },

  methods: {


    getImgUrl(name) {
        return require(`@/assets/stealth/${name}`)
    },
  }
}
</script>

<style scoped lang="scss">


  .stealth{
    .container{

      .graphics{
        width: 90%;
        max-width: 863px;
      }

      .legend{
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: var(--legendColor);
      }
    }
  }
</style>
